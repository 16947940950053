import React, { Component } from 'react';

import Dns from '@mui/icons-material/Dns';
import Newspaper from '@mui/icons-material/Newspaper';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import ChecklistRtl from '@mui/icons-material/ChecklistRtl';
import Tune from '@mui/icons-material/Tune';

const tabs = {

    getTabs: function(){
        return [
            {
                key: 'general_tab',
                title: 'Product Information',
                tab_title: 'Product Info',
                icon: <Dns />,
                rows: [
                    {
                        fields: [
                            {key: 'status', type: 'switch', name: 'status', label: 'Status', validations: ['r'], span: 12, options: [{key: '0', value: 'Disabled'}, {key: '1', value: 'Enabled'}], value: '1'},
                        ],
                    },
                    {
                        fields: [
                            {key: 'name', type: 'input', name: 'name', label: 'Product Name', validations: ['r'], span: 12},
                        ],
                    },
                    {
                        fields: [
                            {key: 'sku', type: 'input', name: 'sku', label: 'SKU', validations: ['r', 'unique|catalog/products/sku_unique'], span: 6},
                        ],
                    },
                    {
                        fields: [
                            {key: 'price', type: 'input', name: 'price', label: 'Price', validations: ['r', 'num'], span: 3},
                            {key: 'special_price', type: 'input', name: 'special_price', label: 'Special Price', validations: ['num'], span: 3},
                        ],
                    },
                    {
                        fields: [
                            {key: 'quantity', type: 'input', name: 'quantity', label: 'Quantity', validations: ['r', 'num'], span: 3},
                        ],
                    },
                    {
                        fields: [
                            {key: 'image', type: 'image', name: 'image', label: 'Product Main Image', validations: [], span: 12, path: 'product_images/', allowed_types: 'jpg,png.jpeg', path_var: 'image_url'}
                        ],
                    },
                    {
                        fields: [
                            {key: 'gallery', type: 'gallery', name: 'gallery', label: 'Product Gallery', validations: [], span: 12, path: 'product_images/', allowed_types: 'jpg,png.jpeg', gallery_type: 'image'}
                        ],
                    },
                ]
            },
            {
                key: 'info_tab',
                title: 'Description',
                icon: <ReceiptLong />,
                rows: [
                    {
                        fields: [
                            {key: 'short_description', type: 'editor', name: 'short_description', label: 'Short Description', validations: [], span: 12},
                        ],
                    },
                    {
                        fields: [
                            {key: 'description', type: 'editor', name: 'description', label: 'Description', validations: [], span: 12},
                        ],
                    },
                ]
            },
            {
                key: 'custom_options_tab',
                tab_title: 'Options',
                title: 'Custom Options',
                icon: <ChecklistRtl />,
                rows: [
                    {
                        fields: [],
                    }
                ]
            },
            
            {
                key: 'meta_tab',
                title: 'SEO',
                icon: <Newspaper />,
                rows: [
                    {
                        fields: [
                            {key: 'url', type: 'input', name: 'url', label: 'SEO Url (Slug)', validations: [], span: 12},
                        ],
                    },
                    {
                        fields: [
                            {key: 'meta_title', type: 'input', rows: 2, name: 'meta_title', label: 'Meta Title', validations: [], span: 12},
                        ],
                    },
                    {
                        fields: [
                            {key: 'meta_keywords', type: 'input', rows: 2, name: 'meta_keywords', label: 'Meta Keywords', validations: [], span: 12},
                        ],
                    },
                    {
                        fields: [
                            {key: 'meta_description', type: 'input', rows: 2, name: 'meta_description', label: 'Meta Description', validations: [], span: 12},
                        ],
                    },
                ]
            },
            {
                key: 'variable_tab',
                title: 'Variable Product',
                icon: <Tune />,
                rows: [
                    {
                        fields: [
                            // {
                            //     key: 'meta_title',
                            //     type: 'html',
                            //     label: 'Meta Title',
                            //     span: 12,
                            //     html: (
                            //         <div>
                            //             {/* <ProductVariables
                            //                 product_group_attributes={this.state.product_group_attributes}
                            //             /> */}
                            //         </div>
                            //     )
                            // },
                        ],
                    },
                ]
            },
        ]
    }
}

export default tabs;