import React, { Component } from 'react';
import { Navigate, useParams, useLocation } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Main from 'components/Main';

import Btn from 'components/Btn';

import Api from 'api/Api';

import Skeleton from '@mui/material/Skeleton';

import CustomerRenderAddress from 'pages/app/customers/blocks/CustomerRenderAddress';
import SalesOrdersTotals from '../orders/SalesOrdersTotals';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Edit from '@mui/icons-material/Edit';

import Price from 'components/Price';

import LayoutHelper from 'helpers/LayoutHelper';

export function withRouter(Children){

    return(props) => {

        const location = useLocation();
        const params = {params: useParams()};

        const pathname = location.pathname;

        params['order_id'] = pathname.order_id;
        params['invoice_id'] = pathname.invoice_id;

        return <Children {...props} params={params} />
    }
}

class SalesOrdersView extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            redirect: false,

            logged_in: false,

            loading: true,

            submitting: false,

            error_message: '',
            success_message: '',

            type: 'preview',

            order: false,
            invoice: false,

            order_id: false,
            invoice_id: false,

            send_email: false,
        }
    }

    componentDidMount = () => {
        
        let account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        let order_id = this.props.params.params.order_id;
        let invoice_id = this.props.params.params.invoice_id;
        
        if(account_token){

            this.setState({account_token: account_token})

            if(order_id){
            
                this.setState({order_id: order_id, type: 'preview'})
                this.loadOrder(account_token, order_id, false)
            }
        }
    }

    render(){

        if(this.state.redirect !== false){

            return <Navigate to={this.state.redirect} />
        }

        return (

            <Main
                active_page="sales"
                
                page="sales_invoice_view"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title={`${this.state.type === 'preview' ? 'New Invoice' : ''}`}
            >
                {this.state.loading
                    ?
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Skeleton variant="text" sx={{fontSize: '6rem', borderRadius: 3}} />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton variant="rectangular" width={'100%'} height={200} sx={{borderRadius: 3}} />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton variant="rectangular" width={'100%'} height={200} sx={{borderRadius: 3}} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton variant="text" sx={{ fontSize: '6rem', borderRadius:3}} />
                                <Skeleton variant="text" sx={{ fontSize: '6rem', borderRadius: 3}} />
                            </Grid>
                        </Grid>
                    :
                        <div className='mt-10'>
                            
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Btn link="/sales/orders" startIcon={<ChevronLeft size={15} className='text-secondary-700' />} size="small" transparent={true} rounded={true}>
                                        Back to Orders
                                    </Btn>
                                </Grid>
                                <Grid item xs={9}>
                                    <div className='justify-end'>
                                        
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{border: '1px solid rgba(0,0,0,.15)', padding: 3, marginTop: 2}} elevation={0} variant='outlined' square={true}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={7} className='fs-12'>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <strong>Order Numebr:</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>#{this.state.order.order_number}</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <strong>Order Date:</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>{this.state.order.order_placed_on_formatted}</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <strong>Customer Name:</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>{this.state.order.customer_name}</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <strong>Customer Email:</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>{this.state.order.customer_email}</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <strong>Customer Contact:</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>{this.state.order.customer_contact}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper elevation={0} sx={{borderTop: '1px solid rgba(0,0,0,.15)', paddingTop: 3, marginTop: 3, backgroundColor: 'transparent'}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Paper elevation={0} sx={{borderRight: '1px solid rgba(0,0,0,.15)', backgroundColor: 'transparent'}}>
                                                                <CustomerRenderAddress
                                                                    label="Billing Address"
                                                                    prefix="billing"
                                                                    address={this.state.order}
                                                                />
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <CustomerRenderAddress
                                                                label="Shipping Address"
                                                                prefix="shipping"
                                                                address={this.state.order}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper elevation={0} sx={{borderTop: '1px solid rgba(0,0,0,.15)', paddingTop: 3, marginTop: 3, backgroundColor: 'transparent'}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Paper elevation={0} sx={{borderRight: '1px solid rgba(0,0,0,.15)', backgroundColor: 'transparent'}}>

                                                                <div className='vertical'>
                                                                    <strong className='fs-14'>Payment Method</strong>

                                                                    <strong className='mt-10 fs-12 gr-7'>{this.state.order._payment_method.label}</strong>
                                                                </div>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <div className='vertical'>
                                                                <strong className='fs-14'>Shipping Method</strong>

                                                                <strong className='mt-10 fs-12 gr-7'>{this.state.order._shipping_method.label}</strong>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper elevation={0} sx={{borderTop: '1px solid rgba(0,0,0,.15)', paddingTop: 3, marginTop: 3, backgroundColor: 'transparent'}}>

                                                    {this.state.order !== false && this.state.order.hasOwnProperty('order_items') &&
                                                    
                                                        <TableContainer>
                                                            <Table size="small">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <strong className='fs-12'>SKU</strong>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <strong className='fs-12'>Product Name</strong>
                                                                        </TableCell>
                                                                        <TableCell align='right'>
                                                                            <strong className='fs-12'>Price</strong>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <strong className='fs-12'>Ordered Qty</strong>
                                                                        </TableCell>
                                                                        <TableCell align='right'>
                                                                            <strong className='fs-12'>Total</strong>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                
                                                                    {this.state.order.order_items.map((row, index) => {
                                                                    
                                                                        return (
                                                                            <TableRow
                                                                                key={`cart_item_row_${index}`}
                                                                                className='has-hoverable-action'
                                                                            >
                                                                                <TableCell>
                                                                                    <span className='fs-12'>{row.sku}</span>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <span className='fs-12'>{row.name}</span>
                                                                                </TableCell>
                                                                                <TableCell align='right'>
                                                                                    <Price
                                                                                        price={row.final_price}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align='center'>
                                                                                    {row.qty}
                                                                                </TableCell>
                                                                                <TableCell align='right'>
                                                                                    <Price
                                                                                        price={row.row_total}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={6}></Grid>
                                            <Grid item xs={6}>
                                                <Paper elevation={0} sx={{backgroundColor: 'transparent', paddingRight: 3}}>
                                                    <SalesOrdersTotals
                                                        totals={this.state.order.totals}
                                                    />

                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.send_email}
                                                                    onChange={(e) => {

                                                                        this.setState({send_email: e.target.checked})
                                                                    }}
                                                                />
                                                            }
                                                            label="Email Copy of Invoice to Customer"
                                                        />
                                                    </FormGroup>

                                                    <div className='justify-end mt-20'>

                                                        <Btn variant="contained" color="secondary" loading={this.state.submitting} onClick={() => {

                                                            this.generateInvoice()
                                                        }}>
                                                            Submit Invoice
                                                        </Btn>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                }
            </Main>
        )
    }
    
    loadOrder = (account_token, row_id, load) => {

        const formData = new FormData();
        formData.append('account_token', account_token);
        formData.append('row_id', row_id);

        var self = this;

        if(load){
        
            this.setState({loading: true});
        }

        Api.post('backend/cart/order/load', formData, function(data){

            if(data.status){

                self.setState({order: data.order}, () => {

                    self.setState({loading: false});
                });
            }
        });
    }

    generateInvoice = () => {

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('row_id', this.state.order.row_id);

        formData.append('email', this.state.send_email === true ? 1 : 0);

        var self = this;

        this.setState({submitting: true});

        Api.post('backend/cart/invoice/generate', formData, function(data){

            if(data.status){

                localStorage.setItem('flash_success_message', data.message);

                self.setState({redirect: `/sales/orders/view/${self.state.order.row_id}`})
            }else{

                this.setState({submitting: false});

                LayoutHelper.addErrorMessage(self, data.message);
            }
        });
    }
}

export default withRouter(SalesOrdersView);