import React, { Component } from 'react';

import DataTable from 'components/wd/data_table/DataTable';
import WdForm from 'components/wd/form/WdForm';

import Main from 'components/Main';

import Btn from 'components/Btn';

import Api from 'api/Api';

import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import Edit from '@mui/icons-material/Edit';

import ConfigProductAttributesOptions from './ConfigProductAttributesOptions';

import ProductTabs from '../../products/ProductTabs';

class ConfigProductAttributes extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            auctions_loading: false,

            logged_in: false,

            error_message: '',
            success_message: '',

            popup: false,

            fields: [],

            groups: [],
            attribute_types: [],
            status_options: [],

            attribute_type: '',

            options: [
                {value: ''}
            ],
            hide_options: true,

            post_options: [],

            do_reload: false,
            active_row: false,
            row: false,

            row_id: false,

            use_for_variable_product: false,
            attribute_group: false,
            place_after: '',
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({account_token: account_token})
            this.init(account_token)
        }

        let tabs = this.attrList()

        this.setState({fields: tabs.fields})
    }

    render(){

        return (

            <Main
                active_page="catalog"
                
                page="catalog_config_product_attributes"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title="Product Attributes"

                title_action={[{key: 'catalog_config_product_attributes_master_add', label: 'Add New Attribute', onClick: () => {

                    this.setState({popup: true})
                }}]}
            >
                
                <>

                    <DataTable
                        index="catalog_config_product_attributes"
                        label="Product Groups"

                        active_row={this.state.active_row}

                        do_reload={this.state.do_reload}
                        relaodDone={() => {

                            this.setState({do_reload: false});
                        }}

                        columns={[
                            {name: 'Title', column: 'name', sortable: true},
                            {name: 'Type', column: 'type', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.attribute_types},

                            {name: 'is Required', column: 'required', sortable: false, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'No'}, {key: '1', value: 'Yes'}]},
                            
                            {name: 'Display in Filters', column: 'display_in_filters', sortable: false, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'No'}, {key: '1', value: 'Yes'}]},
                            
                            {name: 'Use For Variable', column: 'use_for_variable', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'No'}, {key: '1', value: 'Yes'}]},
                            
                            {name: 'Place After', column: 'place_after', search_type: 'match', search_input: 'dropdown', search_data: this.state.fields, renderer: (_row) => {

                                let tabs = this.attrList()

                                let tab_fields = tabs.tab_fields;
                                let fields = tabs.fields;

                                let _tab = tab_fields.find(row => row.tab_key === _row.attribute_group)
                                let _field = fields.find(row => row.key === _row.place_after)

                                if(_tab){

                                    return (
                                        <div className='vertical'>
                                            <span className='fs-11 gr-7'>{_tab.tab_title}</span>
                                            <strong className='fs-11 gr-7'>{_field.value}</strong>
                                        </div>
                                    )
                                }
                            }},
                            {name: 'Status', column: 'status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.status_options},

                        ]}

                        row_actions={(row, row_index) => {

                            return (

                                <div className="hoverable-action">
                                    <div className="align-start">

                                        <Btn size="small" color="secondary" startIcon={<Edit style={{fontSize: 15}} />} onClick={() => {

                                            this.setState({popup: true}, () => {

                                                this.setState({row_id: row.row_id})
                                            })
                                        }}>
                                            Edit
                                        </Btn>
                                    </div>
                                </div>
                            )
                        }}

                        default_sort_by="added_on"

                        api_url="backend/catalog/config/product_attributes/listing"

                        account_token={this.state.account_token}
                        
                        row_id="row_id"
                    />

                    <WdForm
                        title='Attribute'

                        submit_url='backend/catalog/config/product_attributes/save'
                        data_url='backend/catalog/config/product_attributes/data'

                        row_id={this.state.row_id}
                        id="row_id"
                        title_field="name"
                        updated_on="updated_on_formatted"

                        drawer={true}
                        open={this.state.popup}
                        position="right"
                        size="medium"

                        onSubmit={(result) => {

                            this.setState({popup: false, row_id: false, do_reload: true})
                        }}

                        onBack={() => {

                            this.setState({popup: false, row_id: false})
                        }}

                        onDataLoad={(row_data, result) => {

                            this.setState({use_for_variable_product: row_data.use_for_variable == '1' ? true : false})

                            let post_options = [];

                            post_options.push({key: 'attribute_group', value: row_data.attribute_group})
                            post_options.push({key: 'place_after', value: row_data.place_after})

                            if(row_data.hasOwnProperty('options')){
    
                                post_options.push({key: 'options', value: JSON.stringify(row_data.options)})

                                this.setState({options: row_data.options})
                            }

                            if(row_data.type === 'dropdown' || row_data.type === 'multi_select'){

                                this.setState({hide_options: false})
                            }

                            if(row_data.type === 'dropdown'){

                                this.setState({disable_variable: false})
                            }

                            this.setState({post_options: post_options, attribute_group: row_data.attribute_group, place_after: row_data.place_after})
                        }}
                        
                        post_fields={this.state.post_options}

                        externalValidate={() => {

                            let has_error = false;

                            let options = this.state.options;
                            let place_after = this.state.place_after;

                            let errors = [];

                            if(place_after === '' && !this.state.use_for_variable_product){

                                has_error = true;
                                errors.push('Attribute Position is required')
                            }

                            if(this.state.attribute_type === 'dropdown' || this.state.attribute_type === 'multi_select'){

                                options.forEach((_option, index) => {

                                    if(_option.value === ''){

                                        has_error = true;
                                        errors.push('All the options are mandatory to fill. Each entry must be unique.')
                                    }else{

                                        let _row_value = options.findIndex(row => row.value === _option.value);

                                        if(_row_value !== index){

                                            has_error = true;
                                            errors.push('All the options are mandatory to fill. Each entry must be unique.')
                                        }
                                    }
                                })
                            }

                            if(has_error){
                                return {
                                    status: false,
                                    message: errors
                                }
                            }else{
                        
                                return {status: true}
                            }
                        }}

                        fields={{
                            rows: [
                                {
                                    fields: [
                                        {key: 'product_group', type: 'multiselect', name: 'product_group', label: 'Product Group', validations: ['r'], span: 8, options: this.state.groups}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'name', type: 'input', name: 'name', label: 'Name', validations: ['r', 'unique|backend/catalog/config/product_attributes/name_unique'], span: 8}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'code', type: 'input', name: 'code', label: 'Code', validations: ['r', 'unique|backend/catalog/config/product_attributes/code_unique'], span: 8}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'type', type: 'dropdown', name: 'type', label: 'Attribute Type', validations: ['r'], span: 6, options: this.state.attribute_types, onChange: (value) => {

                                            this.setState({attribute_type: value})

                                            if(value === 'dropdown' || value === 'multi_select'){

                                                this.setState({hide_options: false})
                                            }else{

                                                this.setState({hide_options: true})
                                            }

                                            let post_options = this.state.post_options;

                                            if(value === 'dropdown'){

                                                const _field = post_options.findIndex(row => row.key === 'use_for_variable');

                                                if(_field > -1){

                                                    post_options.splice(_field, 1);
                                                }

                                                this.setState({disable_variable: false, post_options: post_options})
                                            }else{

                                                post_options.push({key: 'use_for_variable', value: '0'})

                                                this.setState({disable_variable: true, post_options: post_options})
                                            }
                                        }}
                                    ],
                                },
                                {
                                    fields: [
                                        {
                                            key: 'options',
                                            type: 'html',
                                            remove: this.state.hide_options,
                                            span: 12,
                                            html: (
                                                <ConfigProductAttributesOptions
                                                    options={this.state.options}
                                                    addOption={(index) => {

                                                        let options = this.state.options;

                                                        if(index !== false){

                                                            options.splice(index + 1, 0, {value: '', hex: '', thumb: '', thumb_url: '', sort_order: ''})
                                                        }else{

                                                            options.push({value: '', hex: '', thumb: '', thumb_url: '', sort_order: ''})
                                                        }

                                                        this.setState({options: options})
                                                    }}
                                                    removeOption={(index) => {

                                                        let options = this.state.options;

                                                        options.splice(index, 1);

                                                        let post_options = [];
                                                        post_options.push({key: 'options', value: JSON.stringify(options)})

                                                        this.setState({options: options, post_options: post_options})
                                                    }}
                                                    onChange={(options) => {

                                                        let post_options = [];
                                                        post_options.push({key: 'options', value: JSON.stringify(options)})

                                                        this.setState({options: options, post_options: post_options})
                                                    }}
                                                />
                                            )
                                        }
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'required', type: 'yes_no', name: 'required', label: 'Is Required', validations: [], span: 6}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'display_in_filters', type: 'yes_no', name: 'display_in_filters', label: 'Display In Filters', validations: [], span: 6}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'use_for_variable', type: 'yes_no', name: 'use_for_variable', label: 'Use For Variable Products', validations: [], span: 6, remove: this.state.disable_variable, onChange: (e) => {

                                            this.setState({use_for_variable_product: e})
                                        }}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'position_header', type: 'header', label: 'Attribute Position', span: 12},
                                        {key: 'attributes_position_html', type: 'html', html: this.productTabs(), span: 6}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'status', type: 'switch', name: 'status', label: 'Status', validations: ['r'], span: 6, options: this.state.status_options}
                                    ],
                                },
                            ]
                        }}
                    />
                </>
            </Main>
        )
    }

    productTabs = () => {

        let tabs = this.attrList()

        let optgroup = tabs.optgroup;
        let tab_fields = tabs.tab_fields;

        return(
            <FormControl fullWidth className='wd-form-select'>
                <InputLabel>Place After</InputLabel>
                <Select
                    value={this.state.place_after}
                    label=""
                    variant="standard"
                    size="small"
                    onChange={(e) => {

                        const _v = e.target.value;

                        const _value = tab_fields.find(row => row.field_key === _v);

                        let post_options = this.state.post_options;

                        let _place_after = post_options.findIndex(row => row.key === 'place_after')
                        let _attribute_group = post_options.findIndex(row => row.key === 'attribute_group')

                        if(_place_after > -1){

                            post_options[_place_after]['value'] = _v;
                        }else{

                            post_options.push({key: 'place_after', value: _v})
                        }

                        if(_attribute_group > -1){

                            post_options[_attribute_group]['value'] = _value.tab_key;
                        }else{

                            post_options.push({key: 'attribute_group', value: _value.tab_key})
                        }

                        this.setState({place_after: e.target.value, attribute_group: _value.tab_key, post_options: post_options})
                    }}
                >
                    {optgroup}
                </Select>
            </FormControl>
        )
    }

    attrList = () => {

        let tabs = ProductTabs.getTabs();

        let optgroup = [];
        let tab_fields = [];
        let fields = [];

        if(tabs.length > 0){

            tabs.forEach((_tab) => {

                optgroup.push(<ListSubheader key={_tab.key}>{_tab.title}</ListSubheader>)

                if(_tab.hasOwnProperty('rows')){

                    _tab.rows.forEach((_row) => {

                        if(_row.hasOwnProperty('fields')){

                            _row.fields.forEach((_field) => {

                                optgroup.push(<MenuItem key={_field.key} value={_field.key}><span className='ml-10'>{_field.label}</span></MenuItem>)

                                tab_fields.push({field_key: _field.key, field_title: _field.label, tab_key: _tab.key, tab_title: _tab.title});

                                fields.push({key: _field.key, value: _field.label})
                            })
                        }
                    })
                }
            })
        }

        return {optgroup: optgroup, tab_fields: tab_fields, fields: fields};
    }

    init = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);

        Api.post('backend/catalog/config/product_attributes/init', formData, function(data){

            if(data.status){
            
                that.setState({groups: data.groups, attribute_types: data.attribute_types, status_options: data.status_options});
            }
        });
    }
}

export default ConfigProductAttributes;