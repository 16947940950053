import React, { Component } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Loader from 'components/Loader';

class OrderTotals extends Component {

    constructor(props) {
        super();
        this.state = {

            loading: false
        }
    }

    render(){

        return (
            <div style={{position: 'relative'}}>

                {this.props.totals.length > 0 &&

                    <Table size="small">
                        <TableBody>

                            {this.props.totals.map((_total, index) => {

                                return (

                                    <TableRow key={`total_${index}`} style={{backgroundColor: _total.key === 'totals' ? 'rgba(0,0,0,.05)' : '#fff'}}>
                                        <TableCell align="right" width="70%">
                                            <span className={`vertical ${_total.key === 'totals' ? 'fs-14 fw-bold' : 'fs-12 fw-semibold'}`}>
                                                <span>{_total.label}</span>
                                                {_total.sub_label !== '' &&
                                                
                                                    <span className='op-5 ml-5'>({_total.sub_label})</span>
                                                }
                                            </span>
                                        </TableCell>
                                        <TableCell align="right">
                                            <span className={`${_total.key === 'totals' ? 'fs-14 fw-bold' : 'fs-12 fw-semibold'}`}>{_total.amount}</span>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                }

                <Loader loading={this.props.loading || this.state.loading} />
            </div>
        )
    }
}

export default OrderTotals;