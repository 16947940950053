import React, { Component } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Btn from 'components/Btn';
import Loader from 'components/Loader';

import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import Close from '@mui/icons-material/Close';

import Api from 'api/Api';

class SalesOrderedItems extends Component {

    constructor(props) {
        super();
        this.state = {

            loading: false
        }
    }

    render(){

        return (

            <div style={{position: 'relative'}}>

                {this.props.cart_items.length > 0 &&
                
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong className='fs-12'>SKU</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong className='fs-12'>Product Name</strong>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <strong className='fs-12'>Price</strong>
                                    </TableCell>
                                    <TableCell align="center">
                                        <strong className='fs-12'>Ordered Qty</strong>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <strong className='fs-12'>Total</strong>
                                    </TableCell>
                                    <TableCell align='right'>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            
                                {this.props.cart_items.map((row, index) => {
                                
                                    return (
                                        <TableRow
                                            key={`cart_item_row_${index}`}
                                            className='has-hoverable-action'
                                        >
                                            <TableCell>
                                                <span className='fs-12'>{row.sku}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span className='fs-12'>{row.name}</span>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <span className='fs-12'>{row.final_price}</span>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <div className='align-start'>
                                                    <Btn icon={true} size="small" onClick={() => {

                                                        this.updateItem('remove', row)
                                                    }}>
                                                        <Remove className='c-blue' style={{fontSize: 14}} />
                                                    </Btn>

                                                    <span className='fs-12 ml-10 mr-10 align-center' style={{border: '1px solid rgba(0,0,0,.1)', padding: '5px', width: 30, borderRadius: 5, backgroundColor: 'rgba(0,0,0,.02)'}}>{row.qty}</span>
                                                    
                                                    <Btn icon={true} size="small" onClick={() => {

                                                        this.updateItem('add', row)
                                                    }}>
                                                        <Add className='c-blue' style={{fontSize: 14}} />
                                                    </Btn>
                                                </div>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <span className='fs-12'>{row.row_total}</span>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <div className='hoverable-action'>
                                                    <Btn icon={true} confirm={true} confirm_message="Do you really want to remove this item?" onClick={() => {

                                                        this.removeCartItem(row)
                                                    }}>
                                                        <Close className="c-red" style={{fontSize: 16}} />
                                                    </Btn>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }

                <Loader loading={this.props.loading || this.state.loading} />
            </div>
        )
    }

    updateItem = (action, row) => {

        const formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('cart', this.props.cart_session_id);

        formData.append('product_id', row.product_id);
        formData.append('action', action);

        this.setState({loading: true})

        var self = this;
        Api.post('backend/cart/items/update', formData, function(data){

            self.setState({loading: false})

            if(data.status){

                self.props.updateItems(data.cart.items)
            }
        });
    }

    removeCartItem = (row) => {

        const formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('cart', this.props.cart_session_id);

        formData.append('item', row.row_id);

        this.setState({loading: true})

        var self = this;
        Api.post('backend/cart/items/remove', formData, function(data){

            self.setState({loading: false})

            if(data.status){

                self.props.updateItems(data.cart.items)
            }
        });
    }
}

export default SalesOrderedItems;