import React, { Component } from 'react';

class AppFooter extends Component {

    render () {

        return (

            <>
                
            </>
        )
    }
}

export default AppFooter;