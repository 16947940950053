'use client'

import React, { Component } from 'react';

import Grid from '@mui/material/Grid';

import Price from 'components/Price';

class SalesOrdersTotals extends Component {
    
    render () {

        return (

            <Grid container spacing={0} className='fs-12'>
            
                {this.props.totals.map((_total, index) => {

                    let row_style = {width: '100%', padding: '5px 10px'}

                    if(!_total.hasOwnProperty('sub_rows')){

                        row_style['borderBottom'] = '1px solid rgba(0,0,0,.2)';
                    }

                    return (
                        <React.Fragment key={`total_${index}`}>
                            <Grid item xs={8} className={`vertical ${_total.key === 'totals' ? '' : ''}`}>

                                <div className='justify-end' style={row_style}>
                                    <strong>{_total.label}</strong>

                                    {_total.sub_label !== '' &&
                                    
                                        <span className='ml-2'>({_total.sub_label})</span>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className='justify-end' style={row_style}>
                                    <div className='vertical'>
                                        <Price
                                            price={_total.amount}
                                        />
                                    </div>
                                </div>
                            </Grid>

                            {_total.hasOwnProperty('sub_rows') &&
                            
                                _total.sub_rows.map((_sub_row, _index) => {

                                    let style = {width: '100%', padding: '5px 10px'};

                                    if(_total.sub_rows.length === (_index + 1)){

                                        style['borderBottom'] = '1px solid rgba(0,0,0,.2)'
                                    }

                                    return (
                                        <React.Fragment key={`total_${_total.key}_${_index}`}>
                                            <Grid item xs={8}>
                                                <div className='justify-end' style={style}>
                                                    <label>({_sub_row.tax_slab_label})</label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className='justify-end' style={style}>
                                                    <Price
                                                        price={_sub_row.calculated_tax}
                                                        icon_size={10}
                                                        className="text-right"
                                                        size="xs"
                                                    />
                                                </div>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </React.Fragment>
                    )
                })}
            </Grid>
        )
    }
}

export default SalesOrdersTotals;