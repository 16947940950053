import React, { Component } from 'react';

import Grid from '@mui/material/Grid';

import Main from 'components/Main';

import CatalogCategoriesTree from './CatalogCategoriesTree';

class CatalogCategories extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            auctions_loading: false,

            logged_in: false,

            error_message: '',
            success_message: '',

            popup: false,

            do_reload: false,
            active_row: false,
            row: false,

            selected_category: false
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({account_token: account_token})
        }
    }

    render(){

        return (

            <Main
                active_page="catalog"
                
                page="catalog_categories"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title="Categories"
            >
                
                <Grid container spacing={3}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <CatalogCategoriesTree />
                    </Grid>
                </Grid>
            </Main>
        )
    }
}

export default CatalogCategories;