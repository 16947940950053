import React, { Component } from 'react';
import { useParams, useLocation, Navigate } from "react-router-dom";

import WdForm from 'components/wd/form/WdForm';

import CatalogCategoriesTree from 'pages/app/catalog/categories/CatalogCategoriesTree';

import ProductCustomOptions from './ProductCustomOptions';
import ProductVariables from './ProductVariables';
import ProductStorePricing from './ProductStorePricing';

import Icon from '@mui/material/Icon'

import Main from 'components/Main';

import Api from 'api/Api';

import LayoutHelper from 'helpers/LayoutHelper';

export function withRouter(Children){

    return(props) => {

        const location = useLocation();
        const params = {params: useParams()};

        const pathname = location.pathname;

        let row_id = false;
        let product_group = '';
        let product_type = '';

        let is_view = false;

        if(pathname.indexOf('view') > -1){

            is_view = true;
        }

        params['is_view'] = is_view;

        return <Children {...props} params={params} />
    }
}

class ProductsForm extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,

            redirect: false,

            ready: false,

            row_id: '',

            product: false,

            product_group: '',
            product_type: '',

            error_message: '',
            success_message: '',

            product_groups: [],
            product_types: [],
            stock_status: [],
            custom_option_types: [],
            product_group_attributes: [],

            fields: [],

            stores: [],
            store_pricing: {},

            manage_store_pricing: false,

            post_fields: [
                {key: 'type', value: 'simple'}
            ],

            weight_disabled: false,

            categories: [],

            product_tabs: [],

            variable_product_attributes: [],
            variable_products: []
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            const row_id = this.props.params.params.row_id;
            const product_group = this.props.params.params.product_group;
            const product_type = this.props.params.params.product_type;

            if(row_id && row_id !== 'new'){

                this.setState({row_id: row_id})
            }

            if(product_type){

                this.setState({product_type: product_type})
            }

            if(product_group){

                let post_fields = this.state.post_fields;

                post_fields.push({key: 'product_group', value: product_group})

                this.setState({account_token: account_token, product_group: product_group, post_fields: post_fields})
                this.init(account_token, product_group)

            }else{

                this.setState({redirect: '/catalog/products'})
            }
        }
    }

    productTabs = () => {

        let fields = this.state.fields;

        let _tabs = [];

        fields.forEach((_tab) => {

            let _rows = []

            _tab.icon = <Icon>{_tab.icon}</Icon>

            _tab.rows.forEach((_row) => {

                let _fields = [];

                if(_row.hasOwnProperty('fields')){

                    _row.fields.forEach((_field) => {

                        if(_field.hasOwnProperty('disabled')){

                            _field['disabled'] = this.state[_field['disabled']]
                        }

                        if(_field.hasOwnProperty('component')){

                            _field['html'] = this[_field.component]()
                        }

                        _fields.push(_field)
                    })
                }

                _rows.push(_fields)
            })

            _tabs.push(_tab)
        })

        this.setState({product_tabs: _tabs})
    }

    render(){

        if(this.state.redirect !== false){

            return <Navigate to={this.state.redirect} />
        }

        return (

            <Main
                active_page="catalog"
                
                page="catalog_products_add"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}
            >
                    
                <WdForm
                    title='Product'
                    back_label="Cancel"
        
                    submit_url='backend/catalog/products/save'
                    data_url='backend/catalog/products/single'

                    back_url='catalog/products'

                    onDataLoad={(product, result) => {

                        this.setState({product: product})

                        let post_fields = this.state.post_fields;

                        const type_index = post_fields.findIndex(row => row.key === 'type');

                        if(type_index > 0){

                            post_fields[type_index]['value'] = product.type;
                        }else{

                            post_fields.push({key: 'type', value: product.type})
                        }

                        this.setState({post_fields: post_fields})

                        if(product.hasOwnProperty('variation_attribute_options')){

                            this.setState({variable_products: product.variation_attribute_options}, () => {

                                this.productTabs()
                            })
                        }

                        if(product.hasOwnProperty('product_variation_attributes')){

                            this.setState({variable_product_attributes: product.product_variation_attributes}, () => {

                                this.productTabs()
                            })
                        }

                        this.setState({categories: product.category_ids}, () => {

                            this.productTabs()
                        })
                    }}

                    externalValidate={() => {

                        let has_error = false;

                        let variable_products = this.state.variable_products;
                        let stores = this.state.stores;

                        if(stores.length > 0){

                            variable_products.forEach((_variable_product, _variable_index) => {

                                if(
                                    !_variable_product.hasOwnProperty('price')
                                    ||
                                    (_variable_product.hasOwnProperty('price') && (_variable_product.price === '' || isNaN(_variable_product.price)))
                                ){

                                    variable_products[_variable_index]['price_error'] = true;
                                    has_error = true;
                                }else{

                                    if(variable_products[_variable_index].hasOwnProperty('price_error')){

                                        delete variable_products[_variable_index]['price_error'];
                                    }

                                    if(
                                        _variable_product.hasOwnProperty('special_price')
                                        &&
                                        (isNaN(_variable_product.special_price) || parseFloat(_variable_product.special_price >= _variable_product.price))
                                    ){

                                        variable_products[_variable_index]['special_price_error'] = true;
                                        has_error = true;
                                    }else{

                                        if(variable_products[_variable_index].hasOwnProperty('special_price_error')){

                                            delete variable_products[_variable_index]['special_price_error'];
                                        }
                                    }
                                }

                                if(
                                    !_variable_product.hasOwnProperty('sku')
                                    ||
                                    (_variable_product.hasOwnProperty('sku') && (_variable_product.sku === '' || isNaN(_variable_product.sku)))
                                ){

                                    variable_products[_variable_index]['sku_error'] = true;
                                    has_error = true;
                                }else{

                                    if(variable_products[_variable_index].hasOwnProperty('sku_error')){

                                        delete variable_products[_variable_index]['sku_error'];
                                    }
                                }

                                if(
                                    !_variable_product.hasOwnProperty('qty')
                                    ||
                                    (_variable_product.hasOwnProperty('qty') && (_variable_product.qty === '' || isNaN(_variable_product.qty)))
                                ){

                                    variable_products[_variable_index]['qty_error'] = true;
                                    has_error = true;
                                }else{

                                    if(variable_products[_variable_index].hasOwnProperty('qty_error')){

                                        delete variable_products[_variable_index]['qty_error'];
                                    }
                                }

                                if(
                                    !_variable_product.hasOwnProperty('stock_status')
                                    ||
                                    (_variable_product.hasOwnProperty('stock_status') && _variable_product.stock_status === '')
                                ){

                                    variable_products[_variable_index]['stock_status_error'] = true;
                                    has_error = true;
                                }else{

                                    if(variable_products[_variable_index].hasOwnProperty('stock_status_error')){

                                        delete variable_products[_variable_index]['stock_status_error'];
                                    }
                                }

                                if(_variable_product.hasOwnProperty('store')){

                                    _variable_product.store.forEach((_variable_product_store, _variable_product_store_index) => {

                                        if(
                                            !_variable_product_store.hasOwnProperty('qty')
                                            ||
                                            _variable_product_store.hasOwnProperty('qty') && (_variable_product_store.qty === '' || isNaN(_variable_product_store.qty))
                                        ){

                                            variable_products[_variable_index]['store'][_variable_product_store_index]['qty_error'] = true;
                                            has_error = true;
                                        }
                                    })
                                }else{

                                    let _store_entries = [];

                                    stores.forEach((_store, _store_index ) => {

                                        _store_entries[_store_index] = {}
                                        _store_entries[_store_index]['store_id'] = _store.row_id
                                        _store_entries[_store_index]['status'] = 1
                                        _store_entries[_store_index]['qty_error'] = true
                                        _store_entries[_store_index]['stock_status_error'] = true
                                    })

                                    variable_products[_variable_index]['store'] = _store_entries;
                                    has_error = true;
                                }
                            })
                        }

                        this.setState({variable_products: variable_products}, () => {

                            this.productTabs()
                        })
                
                        if(has_error){
                            return {
                                status: false,
                                message: ['Please check the Variable Product store inventory. All the store level inventory is mandatory to fill in.'],
                                tab_errors: ['variable_tab']
                            }
                        }else{
                    
                            return {status: true}
                        }
                    }}
                
                    row_id={this.state.row_id}
                    id="row_id"
                    title_field="name"
                    updated_on="updated_on_formatted"

                    post_fields={this.state.post_fields}

                    loading={!this.state.ready}

                    fields={{
                        tabs: this.state.product_tabs
                    }}
                />
            </Main>
        )
    }

    variableOptions = () => {

        return (
            <div>
                <ProductVariables
                    product_group_attributes={this.state.product_group_attributes}
                    attribute_products={this.state.variable_products}
                    initAttributes={(attribute_products, selected_attributes) => {

                        let variable_product_attributes = [];

                        if(selected_attributes.length > 0){

                            selected_attributes.forEach((selected_attribute) => {

                                variable_product_attributes.push({attribute_id: selected_attribute.key, attribute_code: selected_attribute.code})
                            })
                        }

                        this.setState({variable_products: attribute_products, variable_product_attributes: variable_product_attributes})

                        let post_fields = this.state.post_fields;

                        const variable_product_attributes_index = post_fields.findIndex(row => row.key === 'variations_attributes');

                        if(variable_product_attributes_index > 0){

                            post_fields[variable_product_attributes_index]['value'] = JSON.stringify(variable_product_attributes);
                        }else{

                            post_fields.push({key: 'variations_attributes', value: JSON.stringify(variable_product_attributes)})
                        }

                        this.setState({post_fields: post_fields}, () => {

                            this.productTabs()
                        })
                    }}
                    updateAttributes={(attribute_products) => {

                        this.setState({variable_products: attribute_products})

                        let _attr_products = [];
                        let _attr_error = false;
                        
                        attribute_products.forEach((_attribute_product) => {

                            if(_attribute_product.qty === '' && _attribute_product.stock_status === ''){

                                _attr_error = true;
                            }

                            let _ids = [];

                            _attribute_product.ids.forEach((_attr_id) => {

                                _ids.push(_attr_id.id)
                            })

                            let _attr_option_values = {}
                            _attr_option_values['option_ids'] = _ids;
                            _attr_option_values['sku'] = _attribute_product.sku;
                            _attr_option_values['code'] = _attribute_product.code;
                            _attr_option_values['price'] = _attribute_product.price;
                            _attr_option_values['special_price'] = _attribute_product.special_price;
                            _attr_option_values['qty'] = _attribute_product.qty;
                            _attr_option_values['stock_status'] = _attribute_product.stock_status;
                            _attr_option_values['status'] = _attribute_product.status;

                            if(_attribute_product.hasOwnProperty('image')){

                                _attr_option_values['image'] = _attribute_product.image;
                            }

                            _attr_products.push(_attr_option_values);

                            if(_attribute_product.hasOwnProperty('store')){

                                _attribute_product.store.map((_store_attribute_product, _index) => {

                                    _attr_products.push({
                                        option_ids: _ids,
                                        code: _attribute_product.code,
                                        // price: _store_attribute_product.price,
                                        // special_price: _store_attribute_product.special_price,
                                        qty: _store_attribute_product.qty,
                                        stock_status: _store_attribute_product.stock_status,
                                        store_id: _store_attribute_product.store_id,
                                        status: _store_attribute_product.status
                                    });
                                })
                            }
                        })

                        let post_fields = this.state.post_fields;

                        const variations_index = post_fields.findIndex(row => row.key === 'variations');

                        if(variations_index > 0){

                            post_fields[variations_index]['value'] = JSON.stringify(_attr_products);
                        }else{

                            post_fields.push({key: 'variations', value: JSON.stringify(_attr_products)})
                        }

                        this.setState({post_fields: post_fields})

                        this.productTabs()
                    }}
                    stores={this.state.stores}

                    updateErrorMessage={(message) => {

                        LayoutHelper.addErrorMessage(this, message)
                    }}
                />
            </div>
        )
    }

    customOptions = () => {

        return (
            <div>
                <ProductCustomOptions
                    custom_option_types={this.state.custom_option_types}
                />
            </div>
        )
    }

    storePricingComponent = () => {

        return (
            <ProductStorePricing
                account_token={this.state.account_token}
                stores={this.state.stores}
                product={this.state.product}

                updateStorePricing={(store_pricing) => {

                    let post_fields = this.state.post_fields;

                    post_fields.push({key: 'store_pricing', value: JSON.stringify(store_pricing)})

                    this.setState({post_fields: post_fields})
                }}

                updateSuccessMessage={(message) => {

                    LayoutHelper.addSuccessMessage(this, message)
                }}
                updateErrorMessage={(message) => {

                    LayoutHelper.addErrorMessage(this, message)
                }}
            />
        )
    }

    categoryComponent = () => {

        return (
            <div>
                <label className='fs-13 fw-bold gr-7'>Categories</label>
                <CatalogCategoriesTree
                    mode="selection"

                    selected_categories={this.state.categories}
                    onSelection={(categories) => {

                        this.setState({categories: categories}, () => {

                            let post_fields = this.state.post_fields;

                            const category_index = post_fields.findIndex(row => row.key === 'category_ids');

                            if(category_index > 0){

                                post_fields[category_index]['value'] = JSON.stringify(categories);
                            }else{

                                post_fields.push({key: 'category_ids', value: JSON.stringify(categories)})
                            }

                            this.setState({post_fields: post_fields})
                            
                            this.productTabs()
                        })
                    }}
                />
            </div>
        )
    }

    init = (account_token, product_group) => {

        const formData = new FormData();
        formData.append('account_token', account_token);
        formData.append('product_group', product_group);

        var self = this;
        Api.post('backend/catalog/products/add/init', formData, function(data){

            if(data.status){

                self.setState({
                    product_groups: data.product_groups,
                    product_types: data.product_types,
                    stock_status: data.stock_status,
                    custom_option_types: data.custom_option_types,
                    product_group_attributes: data.product_group_attributes,
                    fields: data.fields.fields,
                    stores: data.fields.stores,
                    inited: true
                }, () => {

                    self.productTabs()

                    window.setTimeout(() => {

                        self.setState({ready: true})
                    }, 100)
                });
            }
        });
    }
}

export default withRouter(ProductsForm);