import React, { Component } from 'react';
import { Navigate } from "react-router-dom";

import DataTable from 'components/wd/data_table/DataTable';
import Popover from '@mui/material/Popover';

import Grid from '@mui/material/Grid';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import Drawer from '@mui/material/Drawer';

import Main from 'components/Main';

import Btn from 'components/Btn';

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Settings from '@mui/icons-material/Settings';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';

import LayoutHelper from 'helpers/LayoutHelper';

import Api from 'api/Api';

class ProductsList extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            redirect: false,

            auctions_loading: false,

            logged_in: false,

            products: [],

            error_message: '',
            success_message: '',

            product_groups: [],
            product_types: [],
            flagged_attributes: [],

            popup: false,

            group: '',
            type: 'simple',

            group_error: '',
            type_error: '',

            do_reload: false,
            active_row: false,
            row: false,

            selected_tab: '**',
            selected_flag_label: '',

            toolbar_actions: [],

            removing_flag: false,
            manage_flag_label: '',

            manage_flags: false,

            flagged_reload: false,
            adding_flag: false,
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({account_token: account_token})
        }
    }

    render(){

        if(this.state.redirect !== false){

            return <Navigate to={this.state.redirect} />
        }

        return (

            <Main
                active_page="catalog"
                
                page="catalog_products"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title="Products"

                title_action={[{key: 'catalog_products_add', label: 'Add New Product', onClick: (e) => {

                    if(this.state.group === '' && this.state.type === ''){
                    
                        this.setState({popup: e.currentTarget})
                    }else{

                        this.setState({redirect: `/catalog/products/add/${this.state.group}/new/${this.state.type}`})
                    }
                }}]}
            >
                
                <>

                    <DataTable
                        index="catalog_products"
                        label="Products"

                        active_row={this.state.active_row}

                        do_reload={this.state.do_reload}
                        relaodDone={() => {

                            this.setState({do_reload: false});
                        }}

                        updateData={(data) => {

                            if(data.product_groups.length === 1){

                                this.setState({group: data.product_groups[0]['key']})
                            }

                            this.setState({products: data.records, product_groups: data.product_groups, product_types: data.product_types, flagged_attributes: data.flagged_attributes})
                        }}
                        data={this.state.products}

                        tabbed_filters={{
                            filters: [
                                {key: 'flags', label: 'Products', options: this.state.flagged_attributes, default: '**'}
                            ],
                            type: 'tabs'
                        }}
                        onTabClick={(num) => {

                            let flagged_attributes = this.state.flagged_attributes;

                            this.setState({selected_tab: num}, () => {

                                if(num !== '**'){

                                    const flagged_row = flagged_attributes.find(row => row.key === num);

                                    this.setState({selected_flag_label: flagged_row.value})

                                    this.setState({
                                        toolbar_actions: [
                                            {key: 'manage_flags', label: `Manage ${flagged_row.value}`, 'startIcon': <Settings />, onClick: () => {
                                    
                                                this.setState({manage_flags: true})
                                            }}
                                        ]
                                    })
                                }else{

                                    this.setState({toolbar_actions: []})
                                }
                            })
                        }}

                        toolbar_actions={this.state.toolbar_actions}

                        columns={[
                            {name: 'ID', column: 'id', sortable: true, renderer: (row) => {

                                return (
                                    <span>{row.id}</span>
                                )
                            }},
                            {name: 'Title', column: 'name', sortable: true, width: 400},
                            {name: 'SKU', column: 'sku', sortable: true},
                            {name: 'Price', column: 'price', sortable: true, renderer: (row) => {

                                return (
                                    <div className='fs-14'>
                                        {(row.special_price !== '' && row.special_price != '0')
                                            ?
                                                <>
                                                    <strong>{row.special_price}</strong>
                                                    <span className='ml-5 fw-semibold' style={{textDecoration:'line-through'}}> {row.price}  </span>
                                                </>
                                            :
                                                <strong>{row.price}</strong>
                                        }
                                    </div>
                                )
                            }},
                            {name: 'Quantity', column: 'quantity', sortable: true},
                            {name: 'Stock Status', column: 'stock_status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Out Of Stock'}, {key: '1', value: 'In Stock'}]},
                            {name: 'Type', column: 'type', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.product_types},
                            {name: 'Added On', column: 'added_on', sortable: true},
                            {name: 'Status', column: 'status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Disabled'}, {key: '1', value: 'Enabled'}]}
                        ]}

                        row_actions={(row, row_index) => {

                            return (

                                <div className="hoverable-action">
                                    <div className="align-start">

                                        <Btn to={`/catalog/products/add/${row.product_group}/${row.row_id}`} size="small" color="secondary" startIcon={<Edit style={{fontSize: 15}} />}>
                                            Edit
                                        </Btn>

                                        {this.state.selected_tab !== '**' &&
                                        
                                            <Btn icon={true} size="small" className="ml-10" confirm={true} confirm_message={`Do you really want to remove this product from the ${this.state.selected_flag_label} list?`} loading={this.state.removing_flag} onClick={() => {

                                                this.removeFlag(row, this.state.selected_tab)
                                            }}>
                                                <Delete style={{fontSize: 16}} className='c-red' />
                                            </Btn>
                                        }
                                    </div>
                                </div>
                            )
                        }}

                        default_sort_by="id"

                        api_url="backend/catalog/products/listing"

                        account_token={this.state.account_token}
                        
                        row_id="row_id"
                    />

                    <Drawer
                        className='form-drawer right'
                        anchor='right'
                        open={this.state.manage_flags}
                    >
                        
                        <div className={`form-wrapper full`}>
                            <div className='form-container'>
                                <div className='form-header'>
                                    <div className='form-header-action'>

                                        <Btn startIcon={<ArrowBackIosNew />} style={{borderRadius: 0, width: '100%', height: '100%'}} onClick={() => {

                                            this.setState({manage_flags: false, do_reload: true})
                                        }}>
                                            Back
                                        </Btn>
                                    </div>
                                    <div className='form-header-block'>
                                        <div className=''>
                                            
                                            <h1>Manage {this.state.selected_flag_label}</h1>

                                            <strong className='subtitle'> </strong>
                                        </div>
                                        <div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className='form-body'>
                                    <div className={`form-body-wrapper`}>

                                        <DataTable
                                            index="catalog_products_flagged"
                                            label="Flagged Products"

                                            active_row={this.state.active_row}

                                            do_reload={this.state.flagged_reload}
                                            relaodDone={() => {

                                                this.setState({flagged_reload: false});
                                            }}

                                            // updateData={(data) => {

                                            //     if(data.product_groups.length === 1){

                                            //         this.setState({group: data.product_groups[0]['key']})
                                            //     }

                                            //     this.setState({products: data.records, product_groups: data.product_groups, product_types: data.product_types})
                                            // }}
                                            // data={this.state.products}

                                            post_params={[
                                                {key: 'exclude', value: this.state.selected_tab}
                                            ]}

                                            columns={[
                                                {name: 'Title', column: 'name', sortable: true},
                                                {name: 'SKU', column: 'sku', sortable: true},
                                                {name: 'Price', column: 'price', sortable: true, renderer: (row) => {

                                                    return (
                                                        <div className='fs-14'>
                                                            {(row.special_price !== '' && row.special_price != '0')
                                                                ?
                                                                    <>
                                                                        <strong>{row.special_price}</strong>
                                                                        <span className='ml-5 fw-semibold' style={{textDecoration:'line-through'}}> {row.price}  </span>
                                                                    </>
                                                                :
                                                                    <strong>{row.price}</strong>
                                                            }
                                                        </div>
                                                    )
                                                }},
                                                {name: 'Type', column: 'type', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.product_types},
                                                {name: 'Stock Status', column: 'stock_status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Out Of Stock'}, {key: '1', value: 'In Stock'}]},
                                                {name: 'Type', column: 'type', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.product_types},
                                                {name: 'Status', column: 'status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Disabled'}, {key: '1', value: 'Enabled'}]}
                                            ]}

                                            row_actions={(row, row_index) => {

                                                return (

                                                    <div className="hoverable-action">
                                                        <div className="align-start">

                                                            <Btn startIcon={<Add style={{fontSize: 16}} />} size="small" loading={this.state.adding_flag} onClick={() => {

                                                                this.addFlaggedProduct(row, this.state.selected_tab)
                                                            }}>
                                                                Add Product
                                                            </Btn>
                                                        </div>
                                                    </div>
                                                )
                                            }}

                                            default_sort_by="added_on"

                                            api_url="backend/catalog/products/listing"

                                            account_token={this.state.account_token}
                                            
                                            row_id="row_id"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>

                    <Popover
                        anchorEl={this.state.popup}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        keepMounted                        
                        open={this.state.popup !== false ? true : false}
                        onClose={() => {

                            this.setState({popup: false})
                        }}
                    >
                        <div style={{width:300, padding: 30}}>
                            
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl size="small" fullWidth error={this.state.group_error}>
                                        <InputLabel>Product Group</InputLabel>
                                        <Select
                                            fullWidth
                                            variant='standard'
                                            value={this.state.group}
                                            label="Product Group"
                                            onChange={(e) => {

                                                this.setState({group: e.target.value})
                                            }}
                                        >
                                            {this.state.product_groups.map((_group, index) => {
                                            
                                                return <MenuItem key={`product_group_${index}`} value={_group.key}>{_group.value}</MenuItem>
                                            })}
                                        </Select>

                                        {this.state.group_error &&
                                        
                                            <FormHelperText>Please choose product group</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <FormControl size="small" fullWidth error={this.state.type_error}>
                                        <InputLabel>Product Type</InputLabel>
                                        <Select
                                            fullWidth
                                            variant='standard'
                                            value={this.state.type}
                                            label="Product Type"
                                            onChange={(e) => {

                                                this.setState({type: e.target.value})
                                            }}
                                        >
                                            {this.state.product_types.map((_type, index) => {
                                            
                                                return <MenuItem key={`product_type_${index}`} value={_type.key}>{_type.value}</MenuItem>
                                            })}
                                        </Select>

                                        {this.state.type_error &&
                                        
                                            <FormHelperText>Please choose product type</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <div className='justify-center'>
                                        <Btn color="secondary" variant="contained" onClick={() => {

                                            let error = false;

                                            if(this.state.type === ''){

                                                this.setState({type_error: true})
                                                error = true;
                                            }

                                            if(this.state.group === ''){

                                                this.setState({group_error: true})
                                                error = true;
                                            }

                                            if(!error){

                                                this.setState({redirect: `/catalog/products/add/${this.state.group}/new/${this.state.type}`})
                                            }
                                        }}>Create Product</Btn>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Popover>
                </>
            </Main>
        )
    }

    addFlaggedProduct = (row, selected_flag) => {

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('product', row.row_id);
        formData.append('flag', selected_flag);

        this.setState({adding_flag: true})

        var self = this;
        Api.post('backend/catalog/products/flags/add', formData, function(data){

            self.setState({adding_flag: false})

            if(data.status){

                self.setState({flagged_reload: true});
                LayoutHelper.addSuccessMessage(self, data.message)
            }
        });
    }

    removeFlag = (row, selected_flag) => {

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('product', row.row_id);
        formData.append('flag', selected_flag);

        this.setState({removing_flag: true})

        var self = this;
        Api.post('backend/catalog/products/flags/remove', formData, function(data){

            self.setState({removing_flag: false})

            if(data.status){

                self.setState({do_reload: true});
                LayoutHelper.addSuccessMessage(self, data.message)
            }
        });
    }
}

export default ProductsList;