import React, { Component } from 'react';

import { Link, Navigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import LockOutlined from '@mui/icons-material/LockOutlined';

import ValidatorHelper from 'helpers/ValidatorHelper';

import Btn from 'components/Btn';
import Api from 'api/Api';

import bg_image from 'assets/images/login-bg.jpg';
import logo from 'assets/images/logo.png';

class Signin extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            loading: false,

            logged_in: false,

            error_message: '',
            success_message: '',

            email: '',
            password: '',

            email_error: false,
            password_error: false,
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({logged_in: true})
        }
    }

    loginSubmit = (event) => {

        event.preventDefault();

        var _has_error = false;

        const email = this.state.email;
        const password = this.state.password;

        if(!ValidatorHelper.validEmail(email)){

            this.setState({email_error: true})
            _has_error = true;
        }else{

            this.setState({email_error: false})
            _has_error = (!_has_error) ? false : true;
        }

        if(password === ''){

            this.setState({password_error: true})
            _has_error = true;
        }else{

            this.setState({password_error: false})
            _has_error = (!_has_error) ? false : true;
        }
        
        if(!_has_error){

            var that = this;

            var formData = new FormData();

            formData.append('email', email);
            formData.append('password', password);

            this.setState({loading: true})

            Api.post('backend/auth/login', formData, function(data){

                that.setState({loading: false});

                if(data.status){
                
                    localStorage.setItem(process.env.REACT_APP_ACCOUNT_TOKEN, data.account_token);
                    localStorage.setItem(process.env.REACT_APP_ACCOUNT_USER, JSON.stringify(data.user));

                    localStorage.setItem('flash_success_message', data.message);
                    that.setState({success_message: data.message})

                    window.location = '/dashboard';

                }else{

                    that.setState({error_message: data.message})
                    window.setTimeout(() => {

                        that.setState({error_message: ''})
                    }, 5000)
                }
            });
        }
    }

    render(){

        if(this.state.logged_in){

            return <Navigate to="/dashboard" />
        }
    
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    
                    <div className='gr'>
                        <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            
                            <div className='login-container'>
                                <img src={bg_image} />
                            </div>

                            <div className='login-box'>
                                <div className='login-header'>
                                    <img src={logo} height={70} />
                                </div>
                                <div className='login-body'>
                            
                                    <form onSubmit={this.loginSubmit.bind(this)}>
                                    
                                        <Grid container spacing={3}>

                                            {(this.state.error_message !== '' || this.state.success_message !== '') &&
                                            
                                                <Grid item md={12}>

                                                    {this.state.error_message !== '' &&
                                                    
                                                        <Alert severity="error" onClick={() => {

                                                            this.setState({error_message: ''})
                                                        }}>{this.state.error_message}</Alert>
                                                    }

                                                    {this.state.success_message !== '' &&
                                                    
                                                        <Alert severity="success" onClick={() => {

                                                            this.setState({success_message: ''})
                                                        }}>{this.state.success_message}</Alert>
                                                    }
                                                </Grid>
                                            }

                                            <Grid item md={12}>

                                                <div className='align-center vertical'>
                                                    <LockOutlined style={{fontSize:50}} className="op-3" />
                                                    <div className='flex-col ml-5'>
                                                        <span className='fs-16 gr-5 fw-semibold'>Welcome, please login.</span>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item md={12}>

                                                <TextField
                                                    label="Email ID"
                                                    variant="standard"
                                                    value={this.state.email}
                                                    onChange={(e) => {

                                                        this.setState({email: e.target.value})
                                                    }}
                                                    error={this.state.email_error}
                                                    fullWidth
                                                    helperText={this.state.email_error ? 'Please enter valid email address' : ''}
                                                    autoComplete="off"
                                                />
                                            </Grid>
                                            <Grid item md={12}>

                                                <TextField
                                                    label="Password"
                                                    variant="standard"
                                                    type="password"
                                                    value={this.state.password}
                                                    onChange={(e) => {
                                                        
                                                        this.setState({password: e.target.value})
                                                    }}
                                                    error={this.state.password_error}
                                                    fullWidth
                                                    helperText={this.state.password_error ? 'Please enter valid password' : ''}
                                                    autoComplete="off"
                                                />
                                            </Grid>

                                            <Grid item md={12} sx={{marginTop: 3}}>

                                                <Btn color="secondary" variant='contained' sx={{width: '100%'}} size="large" type="submit" loading={this.state.loading}>
                                                    LOGIN
                                                </Btn>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </div>
                        </Box>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default Signin;