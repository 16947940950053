import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";

import Grid from '@mui/material/Grid';

import WdForm from 'components/wd/form/WdForm';

import Api from 'api/Api';

import Main from 'components/Main';

import ConfigProductAttributesOptions from './ConfigProductAttributesOptions';

export function withRouter(Children){

    return(props) => {

        const location = useLocation();
        const params = {params: useParams()};

        const pathname = location.pathname;

        let is_view = false;

        if(pathname.indexOf('view') > -1){

            is_view = true;
        }

        params['is_view'] = is_view;

        return <Children {...props} params={params} />
    }
}

class ConfigProductAttributesAdd extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,
            
            row_id: false,
            is_view: false,

            loading: false,

            logged_in: false,

            error_message: '',
            success_message: '',

            groups: [],
            attribute_types: [],
            status_options: [],

            options: [
                {value: ''}
            ],
            hide_options: true,

            post_options: [],

            disable_variable: true
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem('sbcm_chit');
        
        if(account_token){
            
            this.setState({account_token: account_token});
            this.init(account_token)
        }

        let row_id = this.props.params.params.row_id;

        if(row_id){

            this.setState({row_id: row_id})
        }

        let is_view = this.props.params.is_view;
        this.setState({is_view: is_view})
    }

    render(){

        return (

            <Main
                active_page="catalog"
                page="catalog_config_product_attributes_add"
                error_message={this.state.error_message}
                success_message={this.state.success_message}
            >
            
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={12}>
                        
                        <WdForm
                            title='Attribute'

                            submit_url='catalog/config/product_attributes/save'
                            back_url='catalog/products/config/attributes'
                            data_url='catalog/config/product_attributes/data'
                            edit_url='catalog/products/config/attributes/add'

                            row_id={this.state.row_id}
                            id="row_id"
                            title_field="name"
                            updated_on="updated_on_formatted"

                            is_view={this.state.is_view}

                            size="small"

                            onDataLoad={(row_data, result) => {

                                if(row_data.hasOwnProperty('options')){

                                    let post_options = [];
                                    post_options.push({key: 'options', value: JSON.stringify(row_data.options)})

                                    this.setState({options: row_data.options, post_options: post_options})
                                }

                                if(row_data.type === 'dropdown' || row_data.type === 'multi_select'){

                                    this.setState({hide_options: false})
                                }

                                if(row_data.type === 'dropdown'){

                                    this.setState({disable_variable: false})
                                }
                            }}
                            
                            post_fields={this.state.post_options}

                            externalValidate={() => {

                                let has_error = false;

                                let options = this.state.options;

                                options.forEach((_option, index) => {

                                    if(_option.value === ''){

                                        has_error = true;
                                    }else{

                                        let _row_value = options.findIndex(row => row.value === _option.value);

                                        if(_row_value !== index){

                                            has_error = true;
                                        }
                                    }
                                })

                                if(has_error){
                                    return {
                                        status: false,
                                        message: ['All the options are mandatory to fill. Each entry must be unique.']
                                    }
                                }else{
                            
                                    return {status: true}
                                }
                            }}

                            fields={{
                                rows: [
                                    {
                                        fields: [
                                            {key: 'product_group', type: 'multiselect', name: 'product_group', label: 'Product Group', validations: ['r'], span: 8, options: this.state.groups}
                                        ],
                                    },
                                    {
                                        fields: [
                                            {key: 'name', type: 'input', name: 'name', label: 'Name', validations: ['r', 'unique|catalog/config/product_attributes/name_unique'], span: 8}
                                        ],
                                    },
                                    {
                                        fields: [
                                            {key: 'code', type: 'input', name: 'code', label: 'Code', validations: ['r', 'unique|catalog/config/product_attributes/code_unique'], span: 8}
                                        ],
                                    },
                                    {
                                        fields: [
                                            {key: 'type', type: 'dropdown', name: 'type', label: 'Attribute Type', validations: ['r'], span: 6, options: this.state.attribute_types, onChange: (value) => {

                                                if(value === 'dropdown' || value === 'multi_select'){

                                                    this.setState({hide_options: false})
                                                }else{

                                                    this.setState({hide_options: true})
                                                }

                                                let post_options = this.state.post_options;

                                                if(value === 'dropdown'){

                                                    const _field = post_options.findIndex(row => row.key === 'use_for_variable');

                                                    if(_field > -1){

                                                        post_options.splice(_field, 1);
                                                    }

                                                    this.setState({disable_variable: false, post_options: post_options})
                                                }else{

                                                    post_options.push({key: 'use_for_variable', value: '0'})

                                                    this.setState({disable_variable: true, post_options: post_options})
                                                }
                                            }}
                                        ],
                                    },
                                    {
                                        fields: [
                                            {
                                                key: 'options',
                                                type: 'html',
                                                remove: this.state.hide_options,
                                                html: (
                                                    <ConfigProductAttributesOptions
                                                        options={this.state.options}
                                                        addOption={(index) => {

                                                            let options = this.state.options;

                                                            if(index !== false){

                                                                options.splice(index + 1, 0, {value: ''})
                                                            }else{

                                                                options.push({value: ''})
                                                            }

                                                            this.setState({options: options})
                                                        }}
                                                        removeOption={(index) => {

                                                            let options = this.state.options;

                                                            options.splice(index, 1);

                                                            let post_options = [];
                                                            post_options.push({key: 'options', value: JSON.stringify(options)})

                                                            this.setState({options: options, post_options: post_options})
                                                        }}
                                                        onChange={(options) => {

                                                            let post_options = [];
                                                            post_options.push({key: 'options', value: JSON.stringify(options)})

                                                            this.setState({options: options, post_options: post_options})
                                                        }}
                                                    />
                                                )
                                            }
                                        ]
                                    },
                                    {
                                        fields: [
                                            {key: 'required', type: 'yes_no', name: 'required', label: 'Is Required', validations: [], span: 6}
                                        ],
                                    },
                                    {
                                        fields: [
                                            {key: 'display_in_filters', type: 'yes_no', name: 'display_in_filters', label: 'Display In Filters', validations: [], span: 6}
                                        ],
                                    },
                                    {
                                        fields: [
                                            {key: 'use_for_variable', type: 'yes_no', name: 'use_for_variable', label: 'Use For Variable Products', validations: [], span: 6, remove: this.state.disable_variable, onChange: (e) => {

                                                console.log(e)
                                            }}
                                        ],
                                    },
                                    {
                                        fields: [
                                            {key: 'status', type: 'switch', name: 'status', label: 'Status', validations: ['r'], span: 6, options: this.state.status_options}
                                        ],
                                    },
                                ]
                            }}
                        />
                    </Grid>
                </Grid>
            </Main>
        )
    }

    init = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);

        Api.post('catalog/config/product_attributes/init', formData, function(data){

            if(data.status){
            
                that.setState({groups: data.groups, attribute_types: data.attribute_types, status_options: data.status_options});
            }
        });
    }
}

export default withRouter(ConfigProductAttributesAdd);