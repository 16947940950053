import React, { Component } from 'react';
import { Navigate } from "react-router-dom";

import DataTable from 'components/wd/data_table/DataTable';
import WdForm from 'components/wd/form/WdForm';

import Main from 'components/Main';

import Btn from 'components/Btn';

import Edit from '@mui/icons-material/Edit';

class SalesTaxList extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            redirect: false,

            logged_in: false,

            error_message: '',
            success_message: '',

            row_id: false,

            do_reload: false,

            add_new: false,

            post_fields: []
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({account_token: account_token})
        }
    }

    render(){

        if(this.state.redirect !== false){

            return <Navigate to={this.state.redirect} />
        }

        return (

            <Main
                active_page="sales"
                
                page="sales_tax_master"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title="Tax Classes"

                title_action={[{key: 'sales_tax_master_add_action', label: 'Add New Tax Class', onClick: () => {

                    this.setState({add_new: true})
                }}]}
            >
                
                <>

                    <DataTable
                        index="sales_tax_master"
                        label="Tax Classes"

                        active_row={this.state.active_row}

                        do_reload={this.state.do_reload}
                        relaodDone={() => {

                            this.setState({do_reload: false});
                        }}

                        columns={[
                            {name: 'Label', column: 'label', sortable: true},
                            {name: 'Tax', column: 'tax', sortable: true, renderer: (row) => {

                                return <span className='fs-13 op-7'>{row.tax} %</span>
                            }},
                            {name: 'Status', column: 'status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Disabled'}, {key: '1', value: 'Enabled'}]},
                        ]}

                        row_actions={(row, row_index) => {

                            return (

                                <div className="hoverable-action">
                                    <div className="align-start">

                                        <Btn onClick={() => {

                                            this.setState({add_new: true, row_id: row.row_id})
                                        }} size="small" color="secondary" startIcon={<Edit style={{fontSize: 15}} />}>
                                            Edit
                                        </Btn>
                                    </div>
                                </div>
                            )
                        }}

                        default_sort_by="added_on"

                        api_url="backend/sales/tax/listing"

                        account_token={this.state.account_token}
                        
                        row_id="row_id"
                    />

                    <WdForm
                        title='Tax Class'

                        drawer={true}
                        open={this.state.add_new}
                        position="bottom"
                        size="small"

                        submit_url='backend/sales/tax/save'
                        data_url='backend/sales/tax/data'

                        onSubmit={(result) => {
            
                            this.setState({add_new: false, do_reload: true, row_id: false})
                        }}
                        onBack={() => {
            
                            this.setState({add_new: false, row_id: false})
                        }}

                        row_id={this.state.row_id}
                        id="row_id"
                        title_field="label"
                        updated_on="updated_on_formatted"

                        post_fields={this.state.post_fields}

                        is_view={this.state.is_view}

                        fields={{
                            rows: [
                                {
                                    fields: [
                                        {key: 'label', type: 'input', name: 'label', label: 'Label', validations: ['r'], span: 12}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'tax', type: 'input', name: 'tax', label: 'Tax', validations: ['r', 'num', 'unique|backend/sales/tax/unique_tax'], span: 12, 'after': '%'},
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'status', name: 'status', label: 'Status', type: 'switch', options: [{key: '0', value: 'Disabled'}, {key: '1', value: 'Enabled'}], span: 12}
                                    ]
                                },
                            ]
                        }}
                    />
                </>
            </Main>
        )
    }
}

export default SalesTaxList;