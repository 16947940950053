import React, { Component } from 'react';

import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Btn from 'components/Btn';

import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';

import Api from 'api/Api';

class ConfigProductAttributesOptions extends Component {

    constructor(props) {
        super();
        this.state = {

            uploading_index: false,

            thumb_upload: false
        }
    }

    render(){

        return (

            <>
                <label className='fw-bold fs-12'>Attribute Options</label>
                <TableContainer className='mt-10 mb-10'>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>
                        <TableHead>
                            <TableRow>
                                <TableCell width="30%">
                                    <strong className='fs-12'>Option</strong>
                                </TableCell>
                                <TableCell align="left" width="20%">
                                    <strong className='fs-12'>HEX</strong>
                                </TableCell>
                                <TableCell align="center" width="30%">
                                    <strong className='fs-12'>Thumb</strong>
                                </TableCell>
                                <TableCell align='center' width="15%"></TableCell>
                                <TableCell align="right" width="10%"></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {this.props.options.map((_option, index) => {

                                return (
                                    <TableRow key={`option_row_${index}`}>
                                        <TableCell>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    label=""
                                                    placeholder='Label'
                                                    size="small"
                                                    variant='standard'
                                                    value={_option['value']}
                                                    onChange={(e) => {

                                                        let options = this.props.options;

                                                        options[index]['value'] = e.target.value

                                                        this.props.onChange(options)
                                                    }}
                                                />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    label=""
                                                    placeholder='HEX'
                                                    size="small"
                                                    variant='standard'
                                                    value={_option['hex']}
                                                    onChange={(e) => {

                                                        let options = this.props.options;

                                                        options[index]['hex'] = e.target.value

                                                        this.props.onChange(options)
                                                    }}
                                                />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <div style={{position: 'relative'}}>
                                                <div class="align-center">
                                                    
                                                    {_option.thumb_url !== ''
                                                        ?
                                                            <div class="uploaded-item">

                                                                <a target="_blank" href={_option.thumb_url}>
                                                                    <div class="align-center vertical">
                                                                        <div style={{minHeight: 30, minWidth: 30, maxHeight: 30, maxWidth: 30}} class="align-center">
                                                                            <img src={_option.thumb_url} style={{minHeight: 30, minWidth: 30, maxHeight: 30, maxWidth: 30}} />
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                    
                                                    <div className='ml-10'>
                                                        <Btn size="small" loading={(this.state.uploading_index === index) ? this.state.thumb_upload : false}>
                                                            <label>
                                                                <input type="file" class="hidden" accept="jpg,png,jpeg,webp" onChange={(e) => {

                                                                    this.setState({uploading_index: index})
                                                                    this.uploadFile(e, index)
                                                                }} />

                                                                {_option.thumb_url !== ''
                                                                    ?
                                                                        <span class="fs-11">Change</span>
                                                                    :
                                                                        <span class="fs-11">Upload Thumb</span>
                                                                }
                                                            </label>
                                                        </Btn>

                                                        {_option.thumb_url !== '' &&

                                                            <Btn icon={true} size="small" confirm={true} confirm_message="Do you really want to remove this option?" onClick={() => {

                                                            // this.props.removeOption(index)
                                                            }}>
                                                                <Close style={{fontSize: 18}} className='c-red' />
                                                            </Btn>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    label=""
                                                    placeholder='Sort Order'
                                                    size="small"
                                                    variant='standard'
                                                    value={_option['sort_order']}
                                                    onChange={(e) => {

                                                        let options = this.props.options;

                                                        options[index]['sort_order'] = e.target.value

                                                        this.props.onChange(options)
                                                    }}
                                                />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <div className='align-end'>
                                                <Btn icon={true} size="small" title="Add option below" onClick={() => {

                                                    this.props.addOption(index)
                                                }}>
                                                    <Add style={{fontSize: 18}} className='c-green' />
                                                </Btn>

                                                <Btn icon={true} size="small" confirm={true} confirm_message="Do you really want to remove this option?" onClick={() => {

                                                    this.props.removeOption(index)
                                                }}>
                                                    <Close style={{fontSize: 18}} className='c-red' />
                                                </Btn>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    uploadFile = (e, index) => {

        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append("tmp_file_name", file);

        formData.append('allowed_types', 'jpg,png,webp');
        
        formData.append('upload_dir', 'catalog/attributes/options/');
		
		var self = this;
		
        this.setState({thumb_upload: true})
		
        Api.post('imageuploader', formData, function (data) {

            self.setState({thumb_upload: false, uploading_index: false});

            if(data.status === 'success'){

                let file = `${data.random_dir}${data.main_file_name}`;
                let url = `${data.media_url}${data.upload_dir}${data.random_dir}${data.main_file_name}`;

                let options = self.props.options;

                options[index]['thumb'] = file;
                options[index]['thumb_url'] = url;

                self.props.onChange(options)

            }else{

                self.setState({error_message: data.message}, () => {

                    window.setTimeout(() => {

                        self.setState({error_message: ''})
                    }, 5000)
                });
            }
        });
	}
}

export default ConfigProductAttributesOptions;