import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";

import DataTable from 'components/wd/data_table/DataTable';

import Main from 'components/Main';

import Delete from '@mui/icons-material/Delete';
import Done from '@mui/icons-material/Done';
import X from '@mui/icons-material/X';

import Btn from 'components/Btn';

import Api from 'api/Api';

class ProductsReviewsList extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            redirect: false,

            auctions_loading: false,

            logged_in: false,

            loading: true,
            do_reload: false,

            error_message: '',
            success_message: '',
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({account_token: account_token})
        }
    }

    render(){

        if(this.state.redirect !== false){

            return <Navigate to={this.state.redirect} />
        }

        return (

            <Main
                active_page="catalog"
                
                page="catalog_products_reviews_master"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title="Reviews"
            >
                
                <>

                    <DataTable
                        index="catalog_products_reviews_master"
                        label="Reviews"

                        active_row={this.state.active_row}

                        do_reload={this.state.do_reload}
                        relaodDone={() => {

                            this.setState({do_reload: false});
                        }}

                        loading={this.state.loading}

                        columns={[
                            {name: '', column: 'product_image', sortable: true, hide_search: true, renderer: (row) => {

                                return (
                                    <div style={{width: 70, height: 70, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${row.product_image_url})`}}>

                                    </div>
                                )
                            }},
                            {name: 'Product', column: 'product_name', db_column: 'product.name', sortable: true, width: 200},
                            {name: 'Customer Name', column: 'name', sortable: true},
                            {name: 'Email', column: 'email', sortable: true},
                            {name: 'Rating', column: 'rating', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: 1, value: '1 Star'}, {key: 2, value: '2 Star'}, {key: 3, value: '3 Star'}, {key: 4, value: '4 Star'}, {key: 5, value: '5 Star'}], renderer: (row) => {

                                return <span>{row.rating} Stars</span>
                            }},
                            {name: 'Review', column: 'review', sortable: false},
                            {name: 'Status', column: 'status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Inactive'}, {key: '1', value: 'Active'}], chip_colors: {'0': 'secondary', '1': 'success'}},
                        ]}

                        row_actions={(row, row_index) => {

                            return (

                                <div className="hoverable-action">
                                    <div className="align-start">

                                        <Btn loading={this.state.loading === row.row_id ? true : false} size="small" color="secondary" startIcon={<Delete style={{fontSize: 15}} />} confirm confirm_message="Do you really want to remove this entry?" onClick={() => {

                                            this.remove(row.row_id)
                                        }}>
                                            Remove
                                        </Btn>

                                        {row.status == '0'
                                            ?
                                                <Btn loading={this.state.loading === row.row_id ? true : false} size="small" color="secondary" startIcon={<Done style={{fontSize: 15}} />} confirm confirm_message="Do you really want to activate this review?" onClick={() => {

                                                    this.update(row.row_id, 1)
                                                }}>
                                                    Activate
                                                </Btn>
                                            :
                                                <Btn loading={this.state.loading === row.row_id ? true : false} size="small" color="secondary" startIcon={<X style={{fontSize: 12}} />} confirm confirm_message="Do you really want to disable this review?" onClick={() => {

                                                    this.update(row.row_id, 0)
                                                }}>
                                                    Disable
                                                </Btn>
                                        }
                                    </div>
                                </div>
                            )
                        }}

                        default_sort_by="added_on"

                        api_url="backend/catalog/reviews/listing"

                        account_token={this.state.account_token}
                        
                        row_id="row_id"
                    />
                </>
            </Main>
        )
    }

    remove = (row_id) => {

        const formData = new FormData();
        
        formData.append('account_token', this.state.account_token);
        formData.append('row_id', row_id);

        let that = this;

        this.setState({loading: row_id});

        Api.post('backend/catalog/reviews/remove', formData, function(data){

            that.setState({loading: true});

            if(data.status){

                that.setState({do_reload: true})
            }
        });
    }

    update = (row_id, status) => {

        const formData = new FormData();
        
        formData.append('account_token', this.state.account_token);
        formData.append('row_id', row_id);
        formData.append('status', status);

        let that = this;

        this.setState({loading: row_id});

        Api.post('backend/catalog/reviews/update', formData, function(data){

            that.setState({loading: true});

            if(data.status){

                that.setState({do_reload: true})
            }
        });
    }
}

export default ProductsReviewsList;