import React, { Component } from 'react';

import WdForm from 'components/wd/form/WdForm';
import DataTable from 'components/wd/data_table/DataTable';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Main from 'components/Main';

import Btn from 'components/Btn';

import Edit from '@mui/icons-material/Edit';

import LayoutHelper from 'helpers/LayoutHelper';

class ConfigProductGroups extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            auctions_loading: false,

            logged_in: false,

            error_message: '',
            success_message: '',

            popup: false,

            do_reload: false,
            active_row: false,
            row: false
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({account_token: account_token})
        }
    }

    render(){

        return (

            <Main
                active_page="catalog"
                
                page="catalog_config_product_groups"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title="Product Groups"

                title_action={[{key: 'catalog_config_product_groups_master_add', label: 'Add New Product Group', onClick: () => {

                    this.setState({popup: true})
                }}]}
            >
                
                <>

                    <DataTable
                        index="catalog_config_product_groups"
                        label="Product Groups"

                        active_row={this.state.active_row}

                        do_reload={this.state.do_reload}
                        relaodDone={() => {

                            this.setState({do_reload: false});
                        }}

                        columns={[
                            {name: 'Title', column: 'title', sortable: true},
                            {name: 'Code', column: 'code', sortable: true},
                            {name: 'Details', column: 'details', sortable: true},
                        ]}

                        row_actions={(row, row_index) => {

                            return (

                                <div className="hoverable-action">
                                    <div className="align-start">

                                        <Btn size="small" color="secondary" onClick={(e) => {

                                            this.setState({popup: true, active_row: row_index, row: row})
                                        }} startIcon={<Edit style={{fontSize: 15}} />}>
                                            Edit
                                        </Btn>
                                    </div>
                                </div>
                            )
                        }}

                        default_sort_by="added_on"

                        api_url="catalog/config/product_groups/listing"

                        account_token={this.state.account_token}
                        
                        row_id="row_id"
                    />
                
                    <Dialog open={this.state.popup}>
                        <DialogContent sx={{padding: 0}}>
                            
                            <WdForm
                                size="medium"
                                title='Product Group'
                                back_label="Cancel"

                                submit_url='catalog/config/product_groups/save'
                                data_url='catalog/config/product_groups/data'

                                onSubmit={(result) => {

                                    this.setState({popup: false}, () => {
                                        
                                        this.setState({do_reload: true, active_row: false, row: false});
                                        LayoutHelper.addSuccessMessage(this, result.message)
                                    })
                                }}
                                onBack={() => {

                                    this.setState({popup: false, active_row: false, row: false})
                                }}
    
                                row_id={this.state.row.row_id}
                                id="row_id"
                                title_field="title"
                                updated_on="updated_on_formatted"
                                
                                fields={{
                                    rows: [
                                        {
                                            fields: [
                                                {key: 'title', type: 'input', name: 'title', label: 'Title', validations: ['r', 'unique|catalog/config/product_groups/unique_title'], span: 12},
                                            ]
                                        },
                                        {
                                            fields: [
                                                {key: 'code', type: 'input', name: 'code', label: 'Code', validations: ['r', 'unique|catalog/config/product_groups/unique_code'], span: 12},
                                            ]
                                        },
                                        {
                                            fields: [
                                                {key: 'details', type: 'input', rows: 2, name: 'details', label: 'Details', validations: [], span: 12},
                                            ]
                                        }
                                    ]
                                }}
                            />
                        </DialogContent>
                    </Dialog>
                </>
            </Main>
        )
    }
}

export default ConfigProductGroups;