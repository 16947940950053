import React, { Component } from 'react';

import { Link, Navigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Button from '@mui/material/Button';
import Btn from 'components/Btn';
import IconButton from '@mui/material/IconButton';

import LayoutHelper from 'helpers/LayoutHelper';

import Api from 'api/Api';

import moment from 'moment';

import Main from 'components/Main';
import Loader from 'components/Loader';
import NoData from 'components/blocks/NoData';

import Storefront from '@mui/icons-material/Storefront';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Group from '@mui/icons-material/Group';
import Settings from '@mui/icons-material/Settings';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';

class Dashboard extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            auctions_loading: false,

            logged_in: false,

            error_message: '',
            success_message: '',
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({logged_in: true, account_token: account_token})
        }
    }

    render(){

        return (

            <Main
                page="dashboard"
                active_page="dashboard"
                error_message={this.state.error_message}
                success_message={this.state.success_message}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={12}>
                        <div className='welcome'>
                            <h1>Welcome to</h1>
                            <h2>Sunny Herbals</h2>
                        </div>
                        <div className='dashboard-links'>
                            <ul>
                                <li>
                                    <Link to="/catalog/categories">
                                        <div className='icon'>
                                            <FormatListBulleted />
                                        </div>
                                        <span>Manage Category</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalog/products">
                                        <div className='icon'>
                                            <Storefront />
                                        </div>
                                        <span>Manage Products</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/sales/orders">
                                        <div className='icon'>
                                            <ShoppingCart />
                                        </div>
                                        <span>Manage Orders</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/customers">
                                        <div className='icon'>
                                            <Group />
                                        </div>
                                        <span>Manage Customers</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/settings">
                                        <div className='icon'>
                                            <Settings />
                                        </div>
                                        <span>Manage Settings</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </Main>
        )
    }
}

export default Dashboard;