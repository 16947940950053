import React, { Component } from 'react';
import { Navigate } from "react-router-dom";

import DataTable from 'components/wd/data_table/DataTable';
import WdForm from 'components/wd/form/WdForm';

import Main from 'components/Main';

import Btn from 'components/Btn';

import Api from 'api/Api';

import Edit from '@mui/icons-material/Edit';

class SalesCouponsList extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            redirect: false,

            logged_in: false,

            error_message: '',
            success_message: '',

            discount_types: [],

            do_reload: false,

            row_id: false,
            add_new: false,
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({account_token: account_token})
            this.init(account_token)
        }
    }

    render(){

        if(this.state.redirect !== false){

            return <Navigate to={this.state.redirect} />
        }

        return (

            <Main
                active_page="sales"
                
                page="sales_offers_coupons"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title="Coupons"

                title_action={[{key: 'sales_offers_coupons_add_action', label: 'Add New Coupon', onClick: () => {

                    this.setState({add_new: true})
                }}]}
            >
                
                <>

                    <DataTable
                        index="sales_offers_coupons"
                        label="Coupons"

                        do_reload={this.state.do_reload}
                        relaodDone={() => {

                            this.setState({do_reload: false});
                        }}

                        active_row={this.state.active_row}

                        columns={[
                            {name: 'Title', column: 'title', sortable: true},
                            {name: 'Code', column: 'coupon_code', sortable: true},
                            {name: 'Amount', column: 'amount', sortable: true},
                            {name: 'Disount Type', column: 'amount_type', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.discount_types},
                            {name: 'Valid From', column: 'effective_from', sortable: true, renderer: (row) => {

                                return <span>{row.effective_from_formatted}</span>
                            }},
                            {name: 'Valid Till', column: 'expiration_date', sortable: true, renderer: (row) => {

                                return <span>{row.expiration_date_formatted}</span>
                            }},
                            {name: 'Min Order Amount', column: 'min_order_amount', sortable: true, renderer: (row) => {

                                return row.min_order_amount == '0' ? <span className='fs-12 op-5'>N/A</span> : <span>{row.min_order_amount}</span>
                            }},
                            {name: 'Max Discount', column: 'max_discount', sortable: true, renderer: (row) => {

                                return row.max_discount == '0' ? <span className='fs-12 op-7'>N/A</span> : <span>{row.max_discount}</span>
                            }},
                            {name: 'Coupon Use Limit', column: 'usage_per_coupon', sortable: true, renderer: (row) => {

                                return row.usage_per_coupon == '0' ? <span className='fs-12 op-7'>N/A</span> : <span>{row.usage_per_coupon}</span>
                            }},
                            {name: 'Per Customer Use Limit', column: 'usage_per_customer', sortable: true, renderer: (row) => {

                                return row.usage_per_customer == '0' ? <span className='fs-12 op-7'>N/A</span> : <span>{row.usage_per_customer}</span>
                            }},
                            {name: 'Status', column: 'status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Disabled'}, {key: '1', value: 'Enabled'}]},
                        ]}

                        row_actions={(row, row_index) => {

                            return (

                                <div className="hoverable-action">
                                    <div className="align-start">

                                        <Btn size="small" color="secondary" startIcon={<Edit style={{fontSize: 15}} />} onClick={() => {

                                            this.setState({row_id: row.row_id}, () => {

                                                this.setState({add_new: true})
                                            })
                                        }}>
                                            Edit
                                        </Btn>
                                    </div>
                                </div>
                            )
                        }}

                        default_sort_by="added_on"

                        api_url="sales/coupons/listing"

                        account_token={this.state.account_token}
                        
                        row_id="row_id"
                    />

                    <WdForm
                        title='Coupon'

                        drawer={true}
                        open={this.state.add_new}
                        position="right"
                        size="medium"

                        submit_url='sales/coupons/save'
                        data_url='sales/coupons/data'
                        edit_url='sales/coupons/add'

                        onSubmit={(result) => {
            
                            this.setState({add_new: false, do_reload: true, row_id: false})
                        }}
                        onBack={() => {
            
                            this.setState({add_new: false, row_id: false})
                        }}

                        row_id={this.state.row_id}
                        id="row_id"
                        title_field="title"
                        updated_on="updated_on_formatted"

                        is_view={this.state.is_view}

                        fields={{
                            rows: [
                                {
                                    fields: [
                                        {key: 'title', type: 'input', name: 'title', label: 'Title', validations: ['r'], span: 12}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'coupon_code', type: 'input', name: 'coupon_code', label: 'Coupon Code', validations: ['r', '-_num', 'unique|sales/coupons/unique_code'], span: 12},
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'amount', type: 'input', name: 'amount', label: 'Discount Amount', validations: ['r', 'num'], span: 6, comment: 'Amount entered here will be calculated based on discount type'},
                                        {key: 'amount_type', name: 'amount_type', label: 'Discount Type', type: 'radio', validations: ['r'], options: this.state.discount_types, span: 6}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'effective_from', type: 'date', name: 'effective_from', label: 'Valid From', span: 6},
                                        {key: 'expiration_date', type: 'date', name: 'expiration_date', label: 'Valid Till', span: 6}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'min_order_amount', type: 'input', name: 'min_order_amount', label: 'Min Order Amount', span: 6, validations: ['num'], comment: 'Coupon will be applied only if the order amount is greater than or equals to min amount. 0 and empty means no min order amount'},
                                        {key: 'max_discount', type: 'input', name: 'max_discount', label: 'Max Discount', span: 6, validations: ['num'], comment: 'Limit the max discount to apply. 0 and empty means no max amount limit. Applicable on Percentage discount type only.'}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'usage_per_coupon', name: 'usage_per_coupon', type: 'input', label: 'Coupon Use Limit', validations: ['num'], comment: 'How many times a coupon can be used. 0 and empty means no limits.', span: 6},
                                        {key: 'usage_per_customer', name: 'usage_per_customer', type: 'input', label: 'Per Customer Use Limit', validations: ['num'], comment: 'How many times a single customer can use a coupon', span: 6}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'status', name: 'status', label: 'Status', type: 'switch', validations: [], options: [{key: '0', value: 'Disabled'}, {key: '1', value: 'Enabled'}], span: 12}
                                    ]
                                },
                            ]
                        }}
                    />
                </>
            </Main>
        )
    }

    init = (account_token) => {

        const formData = new FormData();
        formData.append('account_token', account_token);

        var self = this;
        Api.post('sales/coupons/init', formData, function(data){

            if(data.status){

                self.setState({
                    discount_types: data.discount_types
                });
            }
        });
    }
}

export default SalesCouponsList;