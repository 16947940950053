import React, { Component } from 'react';

import Popover from '@mui/material/Popover';

import TextField from '@mui/material/TextField';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import Grid from '@mui/material/Grid';

import Btn from 'components/Btn';

import ExpandMore from '@mui/icons-material/ExpandMore';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Add from '@mui/icons-material/Add';

class ProductCustomOptions extends Component {

    constructor(props) {
        super();
        this.state = {

            expanded: '',

            popup: false,

            options: [],

            label: '',
            type: '',
            is_required: false,

            label_error: false,
            type_error: false,

            show_input_options: true,

            value_label: '',
            value_price: '',
            value_note: '',

            value_label_error: false,
            value_price_error: false,
        }
    }

    render(){

        return (
            <>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <div className='justify-start'>
                            <p className='fs-13 gr-8'>Custom options let customers choose the product variations they want.</p>
                            <Btn className="ml-10" color="secondary" variant="outlined" size="small" startIcon={<Add />} onClick={(e) => {

                               this.setState({popup: e.currentTarget}) 
                            }}>
                                Add New Option
                            </Btn>
                        </div>
                    </Grid>

                    <Grid item xs={12}>

                        {this.renderOptions()}
                    </Grid>
                </Grid>

                <Popover
                    anchorEl={this.state.popup}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted                        
                    open={this.state.popup !== false ? true : false}
                >
                    <div style={{width:300, padding: 30}}>
                        
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Option Label"
                                    variant="standard"
                                    value={this.state.label}
                                    onChange={(e) => {

                                        this.setState({label: e.target.value})
                                    }}
                                    error={this.state.label_error}
                                    helperText={this.state.label_error ? 'This is required entry.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl size="small" fullWidth error={this.state.type_error}>
                                    <InputLabel>Option Type</InputLabel>
                                    <Select
                                        fullWidth
                                        variant='standard'
                                        value={this.state.type}
                                        label="Option Type"
                                        onChange={(e) => {

                                            this.setState({type: e.target.value})
                                        }}
                                    >
                                        {this.props.custom_option_types.map((_type, index) => {
                                        
                                            return <MenuItem key={`option_type_${index}`} value={_type.key}>{_type.value}</MenuItem>
                                        })}
                                    </Select>

                                    {this.state.type_error &&
                                    
                                        <FormHelperText>Please choose option type</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl size="small" fullWidth>
                                    <FormLabel>Is Required</FormLabel>
                                    <FormControlLabel control={<Switch checked={this.state.required} onChange={(e) => {

                                        this.setState({required: e.target.checked})
                                    }} />} label="Yes" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <div className='space-between'>
                                    <Btn size="small" confirm={true} confirm_message="Do you really want to cancel this action?" onClick={() => {

                                        this.setState({label: '', type: '', required: false, popup: false, label_error: false, type_error: false})
                                    }}>
                                        Cancel
                                    </Btn>
                                    <Btn color="secondary" variant="outlined" size="small" onClick={() => {

                                        let error = false;

                                        if(this.state.label === ''){

                                            this.setState({label_error: true})
                                            error = true;
                                        }

                                        if(this.state.type === ''){

                                            this.setState({type_error: true})
                                            error = true;
                                        }

                                        if(!error){

                                            let options = this.state.options;

                                            options.splice(0, 0, {label: this.state.label, type: this.state.type, required: this.state.required});

                                            this.setState({options: options, expanded: 0}, () => {

                                                this.setState({label: '', type: '', required: false, popup: false})
                                            })
                                        }
                                    }}>Add Option</Btn>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Popover>
            </>
        )
    }

    renderOptions = () => {

        let options = this.state.options;

        return options.map((_option, index) => {

            return (

                <Accordion className='accordion' key={`custom_option_${index}`} expanded={this.state.expanded === index} onChange={() => {

                    if(this.state.expanded === index){

                        this.setState({expanded: ''})
                    }else{
                        
                        this.setState({expanded: index})
                    }
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        <div className='justify-start'>
                            <div>
                                <strong className='fs-13'>{_option.label}</strong>
                                {_option.type !== '' &&
                                
                                    <span className='gr-6 fs-13 ml-5'>({this.typeLabel(_option.type)})</span>
                                }
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <div className='vertical'>
                                    <label className='fs-12 gr-5 fw-semibold'>Label</label>
                                    <span className='fs-13 fw-bold gr-6 mt-5'>{_option.label}</span>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className='vertical'>
                                    <label className='fs-12 gr-5 fw-semibold'>Type</label>
                                    <span className='fs-13 fw-bold gr-6 mt-5'>{this.typeLabel(_option.type)}</span>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className='vertical'>
                                    <label className='fs-12 gr-5 fw-semibold'>Is Required</label>
                                    <span className='fs-13 fw-bold gr-6 mt-5'>{_option.required === true ? 'Yes' : 'No'}</span>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <Btn startIcon={<Edit />} size="small">
                                    Edit
                                </Btn>
                            </Grid>
                            
                            {(_option.type === 'dropdown' || _option.type === 'multiselect' || _option.type === 'radio' || _option.type === 'checkbox') &&
                            
                                <Grid item xs={12}>
                                    <div style={{backgroundColor: 'rgba(0,0,0,.05)', marginTop: 20}}>

                                        <Table size="small" className='table'>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" width={200} style={{backgroundColor:'rgba(0,0,0,.1)'}}>
                                                        <label className='fs-12 gr-7 fw-semibold'>Label</label>
                                                    </TableCell>
                                                    <TableCell component="th" width={100} style={{backgroundColor:'rgba(0,0,0,.1)'}}>
                                                        <label className='fs-12 gr-7 fw-semibold'>Price</label>
                                                    </TableCell>
                                                    <TableCell component="th" width={400} style={{backgroundColor:'rgba(0,0,0,.1)'}}>
                                                        <label className='fs-12 gr-7 fw-semibold'>Note</label>
                                                    </TableCell>
                                                    <TableCell style={{backgroundColor:'rgba(0,0,0,.1)'}}></TableCell>
                                                </TableRow>

                                                {_option.hasOwnProperty('values') &&
                                            
                                                    <>
                                                        {_option.values.map((_value, _index) => {

                                                            return (
                                                                <TableRow key={`option_${index}_${_index}`}>
                                                                    <TableCell>
                                                                        <label className='fs-12 fw-bold gr-9'>{_value.label}</label>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <label className='fs-12 fw-bold gr-9'>{_value.price}</label>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <label className='fs-12 fw-bold gr-9'>{_value.note}</label>
                                                                    </TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </>
                                                }

                                                {this.state.show_input_options
                                                    ?
                                                        <TableRow>
                                                            <TableCell>
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    label="Add Label *"
                                                                    variant="standard"
                                                                    value={this.state.value_label}
                                                                    onChange={(e) => {

                                                                        this.setState({value_label: e.target.value})
                                                                    }}
                                                                    error={this.state.value_label_error}
                                                                    helperText={this.state.value_label_error ? 'This is required entry.' : ''}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Add Price"
                                                                    size="small"
                                                                    variant="standard"
                                                                    value={this.state.value_price}
                                                                    onChange={(e) => {

                                                                        this.setState({value_price: e.target.value})
                                                                    }}
                                                                    error={this.state.value_price_error}
                                                                    helperText={this.state.value_price_error ? 'Invalid price.' : ''}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Add Note"
                                                                    size="small"
                                                                    variant="standard"
                                                                    value={this.state.value_note}
                                                                    onChange={(e) => {

                                                                        this.setState({value_note: e.target.value})
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Btn size="small" onClick={() => {

                                                                    let error = false;

                                                                    if(this.state.value_label === ''){

                                                                        this.setState({value_label_error: true})
                                                                        error = true;
                                                                    }else{

                                                                        this.setState({value_label_error: false})
                                                                    }

                                                                    if(this.state.value_price !== '' && isNaN(this.state.value_price)){

                                                                        this.setState({value_price_error: true, value_price: ''})
                                                                        error = true;
                                                                    }else{

                                                                        this.setState({value_price_error: false})
                                                                    }

                                                                    if(!error){

                                                                        let options = this.state.options;

                                                                        let values = [];

                                                                        if(_option.hasOwnProperty('values')){

                                                                            values = _option.values;
                                                                        }

                                                                        values.push({label: this.state.value_label, price: this.state.value_price, note: this.state.value_note})

                                                                        options[index]['values'] = values;

                                                                        this.setState({options: options, value_price: '', value_label: '', value_note: ''})
                                                                    }
                                                                }}>
                                                                    Add
                                                                </Btn>
                                                            </TableCell>
                                                        </TableRow>
                                                    :
                                                        <TableCell colSpan={4}>
                                                            <div className='align-center'>
                                                                <Btn startIcon={<Add />} size="small" onClick={() => {

                                                                    this.setState({show_input_options: true})
                                                                }}>
                                                                    Add Value
                                                                </Btn>
                                                            </div>
                                                        </TableCell>
                                                }
                                                {/* <TableRow>
                                                    <TableCell colSpan={3} align='center'>
                                                        <Btn startIcon={<Add />} size="small" onClick={() => {

                                                            this.setState({options_popup: true, selected_option: _option, selected_option_index: index, show_input_options: true})
                                                        }}>
                                                            Manage Values
                                                        </Btn>
                                                    </TableCell>
                                                </TableRow> */}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <div className='justify-end'>
                                    <Btn startIcon={<Close className='c-red' />} size="small" onClick={(e) => {

                                        e.preventDefault();
                                    }}>
                                        <span className='c-red fs-12 fw-semibold'>Remove Option</span>
                                    </Btn>
                                </div>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            )
        })
    }

    typeLabel = (type) => {

        let custom_option_types = this.props.custom_option_types

        const value = custom_option_types.find(row => row.key === type);

        if(value){

            return value.value
        }
    }
}

export default ProductCustomOptions;