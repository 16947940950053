import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";

import DataTable from 'components/wd/data_table/DataTable';
import WdForm from 'components/wd/form/WdForm';

import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';

import Main from 'components/Main';

import Btn from 'components/Btn';

import Api from 'api/Api';

import CustomersForm from 'pages/app/customers/CustomersForm';
import CustomersAddresses from 'pages/app/customers/CustomersAddresses';

import CustomerRenderCustomer from 'pages/app/customers/blocks/CustomerRenderCustomer';
import CustomerRenderAddress from 'pages/app/customers/blocks/CustomerRenderAddress';

import SalesOrderedItems from './SalesOrderedItems';
import ShippingMethods from '../blocks/ShippingMethods';
import OrderTotals from '../blocks/OrderTotals';

import Loader from 'components/Loader';

import NoData from 'components/blocks/NoData';

import Edit from '@mui/icons-material/Edit';
import Groups from '@mui/icons-material/Groups';
import Person from '@mui/icons-material/Person';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';

class SalesOrdersList extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            redirect: false,

            logged_in: false,

            error_message: '',
            success_message: '',

            customers_groups: [],
            customer: false,

            customer_addresses_type: 'billing',
            customers_addresses: false,

            billing_address: false,
            shipping_address: false,

            row_id: false,

            do_reload: false,

            create_customer: false,
            customer_id: false,
            customer_loading: false,

            customers_list: false,

            products_popup: false,

            /*
            Cart session
            */
            cart_session_id: false,

            cart_items: [],

            cart_loading: false,

            cart_totals: [],

            /*
            Shipping
            */
            shipping_methods: [],

            selected_shipping_method: false,

            shipping_loading: false,

            payment_methods: [],
            shipping_methods: [],
            status_options: []
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({account_token: account_token})
            this.init(account_token)
        }
    }

    render(){

        if(this.state.redirect !== false){

            return <Navigate to={this.state.redirect} />
        }

        return (

            <Main
                active_page="sales"
                
                page="sales_orders"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title="Orders"

                title_action={[{key: 'sales_orders_add_action', label: 'Add New Order', onClick: () => {

                    this.setState({add_new: true})
                }}]}
            >
                
                <>

                    <DataTable
                        index="sales_orders_list"
                        label="Orders"

                        active_row={this.state.active_row}

                        do_reload={this.state.do_reload}
                        relaodDone={() => {

                            this.setState({do_reload: false});
                        }}

                        columns={[
                            {name: 'Order Number', column: 'order_number', sortable: true},
                            {name: 'Name', column: 'customer_name', sortable: true},
                            {name: 'Email', column: 'customer_email', sortable: true},
                            {name: 'Contact', column: 'customer_contact', sortable: true},
                            {name: 'Grand Total', column: 'grand_total', sortable: true},
                            {name: 'Payment Method', column: 'payment_method', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.payment_methods},
                            {name: 'Shipping Method', column: 'shipping_method', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.shipping_methods},
                            {name: 'Purchase Date', column: 'order_placed_on_formatted', sortable: true, hide_search: true},
                            {name: 'Status', column: 'order_status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.status_options},
                        ]}

                        row_actions={(row, row_index) => {

                            return (

                                <div className="hoverable-action">
                                    <div className="align-start">

                                        <Btn link={`/sales/orders/view/${row.row_id}`} size="small" color="secondary" startIcon={<Edit style={{fontSize: 15}} />}>
                                            View
                                        </Btn>
                                    </div>
                                </div>
                            )
                        }}

                        default_sort_by="order_placed_on"

                        api_url="sales/orders/listing"

                        account_token={this.state.account_token}
                        
                        row_id="row_id"
                    />

                    <WdForm
                        title='Order'

                        drawer={true}
                        open={this.state.add_new}
                        position="right"

                        submit_url='cms/pages/save'
                        data_url='cms/pages/data'
                        edit_url='cms/pages/add'

                        onSubmit={(result) => {
            
                            this.setState({add_new: false, do_reload: true, row_id: false, cart_session_id: false, cart_items: []})
                        }}
                        onBack={() => {
            
                            this.setState({add_new: false, row_id: false, cart_session_id: false, cart_items: []})
                        }}

                        row_id={this.state.row_id}
                        id="row_id"
                        title_field="order_number"
                        updated_on="updated_on_formatted"

                        is_view={this.state.is_view}

                        fields={{
                            rows: [
                                {
                                    fields: [
                                        {key: 'customer_header', type: 'header', label: 'Customer', span: 12}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'customer_html', type: 'html', span: 12, html: (
                                            <div style={{position: 'relative'}}>

                                                {this.state.customer
                                                    ?
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={4}>
                                                                <div className='box-block'>
                                                            
                                                                    <div className='box-block-header'>
                                                                        <strong>Details</strong>
                                                                    </div>
                                                                    <div className='box-block-body' style={{minHeight: 150}}>
                                                                        <CustomerRenderCustomer
                                                                            customer={this.state.customer}
                                                                        />

                                                                        <div className='mt-10'>
                                                                            <Btn size="small" className="p-0" onClick={() => {

                                                                                this.setState({customer: false})
                                                                            }}>Change</Btn>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={4}>

                                                                <div className='box-block'>
                                                        
                                                                    <div className='box-block-header'>
                                                                        <strong>Billing Address</strong>
                                                                    </div>
                                                                    <div className='box-block-body' style={{minHeight: 150}}>
                                                        
                                                                        {this.state.billing_address !== false
                                                                            ?
                                                                                <div>
                                                                                    <CustomerRenderAddress
                                                                                        address={this.state.billing_address}
                                                                                    />
                                                                                    <div className='mt-10'>
                                                                                        <Btn size="small" onClick={() => {

                                                                                            this.setState({customers_addresses: true, customer_addresses_type: 'billing'})
                                                                                        }}>Change</Btn>
                                                                                    </div>
                                                                                </div>
                                                                            :
                                                                                <div className='align-center'>
                                                                                    <Btn size="small" variant="outlined" onClick={() => {

                                                                                        this.setState({customers_addresses: true, customer_addresses_type: 'billing'})
                                                                                    }}>Billing Address</Btn>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={4}>

                                                                <div className='box-block'>
                                                        
                                                                    <div className='box-block-header'>
                                                                        <strong>Shipping Address</strong>
                                                                    </div>
                                                                    <div className='box-block-body' style={{minHeight: 150}}>

                                                                        {this.state.shipping_address !== false
                                                                            ?
                                                                                <div>
                                                                                    <CustomerRenderAddress
                                                                                        address={this.state.shipping_address}
                                                                                    />
                                                                                    <div className='mt-10'>
                                                                                        <Btn size="small" onClick={() => {

                                                                                            this.setState({customers_addresses: true, customer_addresses_type: 'shipping'})
                                                                                        }}>Change</Btn>
                                                                                    </div>
                                                                                </div>
                                                                            :
                                                                                <div className='align-center'>
                                                                                    <Btn size="small" variant="outlined" onClick={() => {

                                                                                        this.setState({customers_addresses: true, customer_addresses_type: 'shipping'})
                                                                                    }}>Shipping Address</Btn>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    :
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <NoData icon={<Person />}>
                                                                    <div className='align-center'>
                                                                        
                                                                        <Btn startIcon={<Add />} size="small" onClick={() => {

                                                                            this.setState({create_customer: true})
                                                                        }}>Create New Customer</Btn>

                                                                        <span className='fs-11 gr-6 ml-20'>OR</span>
                                                                        
                                                                        <Btn size="small" className="ml-20" startIcon={<Groups />} onClick={() => {

                                                                            this.setState({customers_list: true})
                                                                        }}>Choose Customer</Btn>
                                                                    </div>
                                                                </NoData>
                                                            </Grid>
                                                        </Grid>
                                                }

                                                <Loader loading={this.state.customer_loading} />
                                                
                                            </div>
                                        )}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'products_html', type: 'html', span: 12, html: (
                                            <Grid container spacing={0} className='mt-20'>
                                                <Grid item xs={12}>
                                                    <div className='justify-start' style={{borderBottom: '1px solid rgba(0,0,0,.1)', paddingBottom:10, marginBottom: 20}}>
                                                        <h5 className="sub-heading mt-2">Products</h5>
                                                        <Btn size="small" className="ml-20" startIcon={<Add />} onClick={() => {

                                                            this.setState({products_popup: true})
                                                        }}>Add Products</Btn>

                                                        <Btn size="small" className="ml-20" startIcon={<Add />} onClick={() => {

                                                            this.setState({products_popup: true})
                                                        }}>Add Products From Wishlist</Btn>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>

                                                    <SalesOrderedItems
                                                        account_token={this.state.account_token}

                                                        cart_session_id={this.state.cart_session_id}
                                                        
                                                        cart_items={this.state.cart_items}
                                                        loading={this.state.cart_loading}

                                                        updateItems={(cart_items) => {

                                                            this.setState({cart_items: cart_items})
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'shipping_header', type: 'header', label: 'Shipping Method', span: 12}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'shipping_html', type: 'html', span: 12, html: (

                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>

                                                    {this.state.cart_session_id
                                                        ?
                                                            <ShippingMethods
                                                                loading={this.state.shipping_loading}
                                                                methods={this.state.shipping_methods}

                                                                selected_shipping_method={this.state.selected_shipping_method}
                                                                onSelect={(method) => {

                                                                    this.setState({selected_shipping_method: method}, () => {

                                                                        this.updateShippingMethod(method)
                                                                    })
                                                                }}
                                                            />
                                                        :
                                                            <NoData icon={<FormatListBulleted style={{fontSize: 40}} />}>
                                                                <strong className='fs-12 op-7'>Shipping methods will be available after add to cart.</strong>
                                                            </NoData>
                                                    }
                                                </Grid>
                                            </Grid>
                                        )}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'totals_html', type: 'html', span: 12, html: (
                                            <div style={{marginBottom: 100}}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}></Grid>
                                                    <Grid item xs={2}></Grid>
                                                    <Grid item xs={4}>

                                                        <OrderTotals
                                                            totals={this.state.cart_totals}    
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    ]
                                }
                            ]
                        }}
                    />

                    <CustomersForm
                        drawer={true}
                        open={this.state.create_customer}
                        position="right"

                        onBack={() => {

                            this.setState({create_customer: false, customer_id: false})
                        }}
                        onSubmit={(result) => {

                            this.setState({customer_id: result.row_id})
                        }}

                        row_id={this.state.customer_id}
                    />

                    <Drawer
                        anchor="right"
                        open={this.state.customers_addresses}
                    >
                        <div style={{padding: 20}}>

                            <strong className='fs-16 fw-bold'>Addresses</strong>

                            <CustomersAddresses
                                source="sales"

                                addAddress={(row) => {

                                    this.setState({customers_addresses: false})

                                    if(this.state.customer_addresses_type === 'billing'){

                                        this.setState({billing_address: row})
                                    }

                                    if(this.state.customer_addresses_type === 'shipping'){

                                        this.setState({shipping_address: row})
                                    }
                                }}
                            />
                        </div>
                    </Drawer>

                    <Drawer
                        anchor="right"
                        open={this.state.customers_list}
                    >
                        <div style={{padding: 20}}>

                            <div className='space-between'>
                                <strong className='fs-16 fw-bold'>Customers</strong>
                                <Btn icon={true} onClick={() => {

                                    this.setState({customers_list: false})
                                }}>
                                    <Close />
                                </Btn>
                            </div>

                            <DataTable
                                index="customers_master_sales"
                                label="Customers"

                                columns={[
                                    {name: 'Name', column: 'name', sortable: true},
                                    {name: 'Email', column: 'email', sortable: true},
                                    {name: 'Contact', column: 'contact', sortable: true},
                                    {name: 'Customer Group', column: 'customer_group', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.customers_groups},
                                    {name: 'Newsletter Subscription', column: 'newsletter', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'No'}, {key: '1', value: 'Yes'}]},
                                    {name: 'Status', column: 'status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Disabled'}, {key: '1', value: 'Enabled'}]},
                                ]}

                                row_actions={(row, row_index) => {

                                    return (

                                        <div className="hoverable-action">
                                            <div className="align-start">

                                                <Btn size="small" color="secondary" startIcon={<Add style={{fontSize: 15}} />} onClick={() => {

                                                    this.setState({customers_list: false}, () => {

                                                        this.loadCustomer(row.row_id)
                                                    })
                                                }}>
                                                    Add
                                                </Btn>
                                            </div>
                                        </div>
                                    )
                                }}

                                default_sort_by="added_on"

                                api_url="customers/listing"

                                account_token={this.state.account_token}
                                
                                row_id="row_id"
                            />
                        </div>
                    </Drawer>

                    <Drawer
                        anchor="right"
                        open={this.state.products_popup}
                    >
                        <div style={{padding: 20}}>

                            <div className='space-between'>
                                <strong className='fs-16 fw-bold'>Products</strong>
                                <Btn icon={true} onClick={() => {

                                    this.setState({products_popup: false})
                                }}>
                                    <Close />
                                </Btn>
                            </div>

                            <DataTable
                                index="catalog_products_sales"
                                label="Products"

                                columns={[
                                    {name: 'Title', column: 'name', sortable: true},
                                    {name: 'SKU', column: 'sku', sortable: true},
                                    {name: 'Price', column: 'price', sortable: true, renderer: (row) => {

                                        return (
                                            <div className='fs-14'>
                                                {(row.special_price !== '' && row.special_price != '0')
                                                    ?
                                                        <>
                                                            <strong>{row.special_price}</strong>
                                                            <span className='ml-5 fw-semibold' style={{textDecoration:'line-through'}}> {row.price}  </span>
                                                        </>
                                                    :
                                                        <strong>{row.price}</strong>
                                                }
                                            </div>
                                        )
                                    }},
                                ]}

                                row_actions={(row, row_index) => {

                                    return (

                                        <div className="hoverable-action">
                                            <div className="align-start">

                                                <Btn size="small" color="secondary" startIcon={<Add style={{fontSize: 15}} />} onClick={() => {

                                                    this.addToCart(row)
                                                }}>
                                                    Add
                                                </Btn>
                                            </div>
                                        </div>
                                    )
                                }}

                                default_sort_by="added_on"

                                api_url="catalog/products/listing"

                                account_token={this.state.account_token}
                                
                                row_id="row_id"
                            />
                        </div>
                    </Drawer>
                </>
            </Main>
        )
    }
    
    init = (account_token) => {

        const formData = new FormData();
        formData.append('account_token', account_token);

        var self = this;
        Api.post('sales/orders/init', formData, function(data){

            if(data.status){

                self.setState({
                    payment_methods: data.payment_methods,
                    shipping_methods: data.shipping_methods,
                    status_options: data.status_options
                });
            }
        });
    }

    loadCustomer = (row_id) => {

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('customer', row_id);

        this.setState({customer_loading: true})

        var self = this;
        Api.post('backend/customer/load', formData, function(data){

            self.setState({customer_loading: true})

            if(data.status){

                self.setState({
                    customer: data.customer
                }, () => {

                    self.updateCustomer(data.customer)
                });
            }
        });
    }

    updateCustomer = (customer) => {

        this.setState({customer_loading: true})

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('customer', customer.row_id);

        if(this.state.cart_session_id !== false){

            formData.append('cart', this.state.cart_session_id);
        }

        var self = this;
        Api.post('backend/cart/customer/add', formData, function(data){

            self.setState({customer_loading: false});
        });
    }

    addToCart = (row) => {

        this.setState({products_popup: false, cart_loading: true})

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('product', row.row_id);

        if(this.state.cart_session_id !== false){

            formData.append('cart', this.state.cart_session_id);
        }

        var self = this;
        Api.post('backend/cart/items/add', formData, function(data){

            self.setState({cart_loading: false});

            if(data.status){

                self.setState({
                    cart_session_id: data.cart_id,
                    cart_items: data.cart.items,
                    cart_totals: data.cart_totals
                });

                if(self.state.shipping_methods.length === 0){

                    self.shippingMethods()
                }
            }
        });
    }

    shippingMethods = () => {

        this.setState({shipping_loading: true})

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);

        formData.append('cart', this.state.cart_session_id);

        var self = this;
        Api.post('backend/cart/shipping/methods', formData, function(data){

            self.setState({shipping_loading: false});

            if(data.status){

                self.setState({shipping_methods: data.methods});
            }
        });
    }

    updateShippingMethod = (method) => {

        this.setState({shipping_loading: true})

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('cart', this.state.cart_session_id);
        formData.append('method', method);

        var self = this;
        Api.post('backend/cart/shipping/update', formData, function(data){

            self.setState({shipping_loading: false});

            if(data.status){

                self.setState({cart_totals: data.cart_totals});
            }
        });
    }
}

export default SalesOrdersList;