import React, { Component } from 'react';

import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import Switch from '@mui/material/Switch';

import Btn from 'components/Btn';

import Api from 'api/Api';

class ProductStorePricing extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,

            manage_store_pricing: false,

            store_pricing: {},

            store_pricing_errors: {},

            updating: false,
        }
    }

    render(){

        return (
            <div>
                <label className='fs-13 fw-bold gr-7'>Store Pricing</label>

                <div className='vertical align-start'>
                    <Btn size="small" onClick={(e) => {

                        this.setState({manage_store_pricing: e.currentTarget})

                        if(this.props.product !== false){
                        
                            this.loadPrices(this.props.product.row_id)
                        }
                    }}>Manage</Btn>
                </div>

                <Drawer
                    anchor="right"
                    open={this.state.manage_store_pricing !== false ? true : false}
                    onClose={() => {

                        this.setState({manage_store_pricing: false})
                    }}
                    className='form-drawer right'
                >
                    <div className='form-wrapper'>
                        <div className='form-container'>
                            <div className="form-header">
                                <div className="form-header-block">
                                    <div className="">
                                        <h1>Store Price / Inventory</h1>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-body' style={{position: 'relative'}}>
                                <div className='form-body-wrapper'>

                                    <Table className='table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="40%">
                                                    <strong className='fs-12'>Store</strong>
                                                </TableCell>
                                                {/* <TableCell width="15%">
                                                    <strong className='fs-12'>Price</strong>
                                                </TableCell>
                                                <TableCell width="15%">
                                                    <strong className='fs-12'>Special Price</strong>
                                                </TableCell> */}
                                                <TableCell width="15%">
                                                    <strong className='fs-12'>Quantity</strong>
                                                </TableCell>
                                                <TableCell width="15%">
                                                    <strong className='fs-12'>Stock Status</strong>
                                                </TableCell>
                                                <TableCell width="15%">
                                                    <strong className='fs-12'>Status</strong>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        
                                            {this.props.stores.map((_store) => {

                                                return (
                                                    <TableRow key={`store_${_store.row_id}`}>
                                                        <TableCell>
                                                            <strong className='fs-12'>{_store.title}</strong>
                                                        </TableCell>
                                                        {/* <TableCell>
                                                            <TextField
                                                                label="Price"
                                                                variant="standard"
                                                                size='small'
                                                                placeholder='Enter Price'
                                                                value={(this.state.store_pricing.hasOwnProperty(_store.row_id) && this.state.store_pricing[_store.row_id].hasOwnProperty('price')) ? this.state.store_pricing[_store.row_id]['price'] : ''}
                                                                onChange={(e) => {

                                                                    let store_pricing = this.state.store_pricing;

                                                                    if(store_pricing.hasOwnProperty(_store.row_id)){

                                                                        store_pricing[_store.row_id]['price'] = e.target.value
                                                                    }else{

                                                                        store_pricing[_store.row_id] = {}
                                                                        store_pricing[_store.row_id]['price'] = e.target.value
                                                                    }

                                                                    this.setState({store_pricing: store_pricing})
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                label="Special Price"
                                                                variant="standard"
                                                                size='small'
                                                                placeholder='Enter Special Price'
                                                                value={(this.state.store_pricing.hasOwnProperty(_store.row_id) && this.state.store_pricing[_store.row_id].hasOwnProperty('special_price')) ? this.state.store_pricing[_store.row_id]['special_price'] : ''}
                                                                onChange={(e) => {

                                                                    let store_pricing = this.state.store_pricing;

                                                                    if(store_pricing.hasOwnProperty(_store.row_id)){

                                                                        store_pricing[_store.row_id]['special_price'] = e.target.value
                                                                    }else{

                                                                        store_pricing[_store.row_id] = {}
                                                                        store_pricing[_store.row_id]['special_price'] = e.target.value
                                                                    }

                                                                    this.setState({store_pricing: store_pricing})
                                                                }}
                                                            />
                                                        </TableCell> */}
                                                        <TableCell>
                                                            <TextField
                                                                label="Quantity"
                                                                variant="standard"
                                                                size='small'
                                                                placeholder='Quantity'
                                                                value={(this.state.store_pricing.hasOwnProperty(_store.row_id) && this.state.store_pricing[_store.row_id].hasOwnProperty('qty')) ? this.state.store_pricing[_store.row_id]['qty'] : ''}
                                                                onChange={(e) => {

                                                                    let store_pricing = this.state.store_pricing;

                                                                    if(store_pricing.hasOwnProperty(_store.row_id)){

                                                                        store_pricing[_store.row_id]['qty'] = e.target.value
                                                                    }else{

                                                                        store_pricing[_store.row_id] = {}
                                                                        store_pricing[_store.row_id]['qty'] = e.target.value
                                                                    }

                                                                    this.setState({store_pricing: store_pricing})
                                                                }}
                                                                error={(this.state.store_pricing.hasOwnProperty(_store.row_id) && this.state.store_pricing[_store.row_id].hasOwnProperty('qty_error')) ? true : false}
                                                                helperText={(this.state.store_pricing.hasOwnProperty(_store.row_id) && this.state.store_pricing[_store.row_id].hasOwnProperty('qty_error')) ? "Please enter valid qty" : ''}
                                                                onBlur={(e) => {

                                                                    this.validateInput('qty', 'num', _store)
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                error={(this.state.store_pricing.hasOwnProperty(_store.row_id) && this.state.store_pricing[_store.row_id].hasOwnProperty('stock_status_error')) ? true : false}
                                                            >
                                                                <InputLabel>Stock Status</InputLabel>
                                                                <Select
                                                                    size='small'
                                                                    fullWidth
                                                                    label="Stock Status"
                                                                    value={(this.state.store_pricing.hasOwnProperty(_store.row_id) && this.state.store_pricing[_store.row_id].hasOwnProperty('stock_status')) ? this.state.store_pricing[_store.row_id]['stock_status'] : ''}
                                                                    onChange={(e) => {

                                                                        let store_pricing = this.state.store_pricing;

                                                                        if(store_pricing.hasOwnProperty(_store.row_id)){

                                                                            store_pricing[_store.row_id]['stock_status'] = e.target.value
                                                                        }else{

                                                                            store_pricing[_store.row_id] = {}
                                                                            store_pricing[_store.row_id]['stock_status'] = e.target.value
                                                                        }

                                                                        if(store_pricing[_store.row_id].hasOwnProperty('stock_status_error')){

                                                                            delete store_pricing[_store.row_id]['stock_status_error'];
                                                                        }

                                                                        this.setState({store_pricing: store_pricing})
                                                                    }}
                                                                >
                                                                    <MenuItem value={1}>In Stock</MenuItem>
                                                                    <MenuItem value={0}>Out Of Stock</MenuItem>
                                                                </Select>

                                                                {
                                                                    (this.state.store_pricing.hasOwnProperty(_store.row_id) && this.state.store_pricing[_store.row_id].hasOwnProperty('stock_status_error')) &&
                                                                        
                                                                        <FormHelperText>This is required entry.</FormHelperText>
                                                                }
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Switch
                                                                checked={
                                                                    (
                                                                        this.state.store_pricing.hasOwnProperty(_store.row_id)
                                                                        &&
                                                                        (this.state.store_pricing[_store.row_id].hasOwnProperty('status') == '1')
                                                                    )
                                                                        ?
                                                                            true
                                                                        :
                                                                            !this.state.store_pricing.hasOwnProperty(_store.row_id)
                                                                                ?
                                                                                    true
                                                                                :
                                                                                    false
                                                                }
                                                                onChange={(e) => {

                                                                    let store_pricing = this.state.store_pricing;

                                                                    if(store_pricing.hasOwnProperty(_store.row_id)){

                                                                        store_pricing[_store.row_id]['status'] = e.target.checked === true ? 1 : 0
                                                                    }else{

                                                                        store_pricing[_store.row_id] = {}
                                                                        store_pricing[_store.row_id]['status'] = e.target.checked === true ? 1 : 0
                                                                    }

                                                                    this.setState({store_pricing: store_pricing})
                                                                }}
                                                                size="small"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <div className='justify-end mt-10'>
                                                        <Btn size="small" onClick={() => {

                                                            this.setState({manage_store_pricing: false})
                                                        }}>Back</Btn>
                                                        <Btn loading={this.state.updating} color="secondary" variant="contained" size="small" className="ml-10" type="button" onClick={() => {

                                                            this.updateStorePricing()
                                                        }}>Save</Btn>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </div>
        )
    }

    loadPrices = (product_id) => {

        let store_pricing = this.state.store_pricing;

        if(Object.keys(store_pricing).length === 0){

            const formData = new FormData();
            formData.append('account_token', this.props.account_token);
            formData.append('product', product_id);

            var self = this;
            Api.post('backend/catalog/store/pricing', formData, function(data){

                if(data.status){

                    let store_pricing = {}

                    data.store_prices.map((_store_price) => {

                        store_pricing[_store_price.store_id] = {}
                        // store_pricing[_store_price.store_id]['price'] = _store_price.price;
                        // store_pricing[_store_price.store_id]['special_price'] = _store_price.special_price;
                        store_pricing[_store_price.store_id]['qty'] = _store_price.qty;
                        store_pricing[_store_price.store_id]['stock_status'] = _store_price.stock_status;
                        store_pricing[_store_price.store_id]['status'] = _store_price.status;
                    })

                    self.setState({store_pricing: store_pricing});
                }
            });
        }
    }

    updateStorePricing = () => {

        let store_pricing = this.state.store_pricing;
        let stores = this.props.stores;

        let errors = false;

        stores.forEach((_store) => {

            let _store_id = _store.row_id;

            if(store_pricing.hasOwnProperty(_store.row_id)){

                if(
                    !store_pricing[_store_id].hasOwnProperty('qty')
                    ||
                    store_pricing[_store_id]['qty'] === ''
                    ||
                    isNaN(store_pricing[_store_id]['qty'])
                ){
    
                    store_pricing[_store_id]['qty_error'] = true
                    errors = true;
                }
    
                if(
                    !store_pricing[_store_id].hasOwnProperty('stock_status')
                    ||
                    store_pricing[_store_id]['stock_status'] === ''
                ){
    
                    store_pricing[_store_id]['stock_status_error'] = true
                    errors = true;
                }
    
                if(!store_pricing[_store_id].hasOwnProperty('status')){
    
                    store_pricing[_store_id]['status'] = 1
                }
            }else{

                store_pricing[_store_id] = {}
                store_pricing[_store_id]['qty_error'] = true;
                store_pricing[_store_id]['stock_status_error'] = true;
                store_pricing[_store_id]['status'] = 1

                errors = true;
            }
        })
        
        if(errors){

            this.setState({store_pricing: store_pricing})
            return false;
        }

        if(this.props.product === false){

            this.props.updateStorePricing(store_pricing)
            this.setState({manage_store_pricing: false})
        }else{

            const formData = new FormData();
            formData.append('account_token', this.props.account_token);
            formData.append('product', this.props.product.row_id);
            formData.append('store_pricing', JSON.stringify(store_pricing));

            var self = this;

            this.setState({updating: true})

            Api.post('backend/catalog/store/update_pricing', formData, function(data){

                self.setState({updating: false})

                if(data.status){

                    self.props.updateSuccessMessage(data.message)
                    self.setState({manage_store_pricing: false})
                }else{

                    self.props.updateErrorMessage(data.message)
                }
            });
        }
    }

    validateInput = (field, type, _store) => {

        let store_pricing = this.state.store_pricing;

        if(store_pricing.hasOwnProperty(_store.row_id)){

            if(store_pricing[_store.row_id].hasOwnProperty(field)){

                if(
                    store_pricing[_store.row_id][field] === ''
                    ||
                    (
                        type === 'num'
                        &&
                        isNaN(store_pricing[_store.row_id][field])
                    )
                ){

                    store_pricing[_store.row_id][`${field}_error`] = true
                }else{

                    if(store_pricing[_store.row_id].hasOwnProperty(`${field}_error`)){

                        delete store_pricing[_store.row_id][`${field}_error`];
                    }
                }
            }else{

                store_pricing[_store.row_id][`${field}_error`] = true
            }
        }else{

            store_pricing[_store.row_id] = {}
            store_pricing[_store.row_id][`${field}_error`] = true
        }

        this.setState({store_pricing: store_pricing})
    }
}

export default ProductStorePricing;