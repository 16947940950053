import React, { Component } from 'react';
import { Navigate } from "react-router-dom";

import DataTable from 'components/wd/data_table/DataTable';
import WdForm from 'components/wd/form/WdForm';

import Main from 'components/Main';

import Btn from 'components/Btn';

import Edit from '@mui/icons-material/Edit';

import Api from 'api/Api';

import LayoutHelper from 'helpers/LayoutHelper';

import NoData from 'components/blocks/NoData';

class SalesTaxSlabs extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            redirect: false,

            initing: true,

            logged_in: false,

            error_message: '',
            success_message: '',

            row_id: false,

            do_reload: false,

            add_new: false,

            post_fields: [],

            tax_classes: [],

            active_tab: '',

            countries: [],
            country_states: [],

            selected_states: [],

            selected_country: '',
            selected_state: '',

            active_tax_class_label: ''
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({account_token: account_token}, () => {

                this.init(account_token)
            })
        }
    }

    render(){

        if(this.state.redirect !== false){

            return <Navigate to={this.state.redirect} />
        }

        return (

            <Main
                active_page="sales"
                
                page="sales_tax_slabs"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title="Tax Slabs"

                title_action={[{key: 'sales_tax_slabs_add', label: 'Add New Slab', onClick: () => {

                    this.setState({add_new: true})
                }}]}
            >
                
                <>
                    {this.state.active_tab !== ''
                        ?

                            <DataTable
                                index="sales_tax_slabs"
                                label="Tax Slabs"

                                active_row={this.state.active_row}

                                do_reload={this.state.do_reload}
                                relaodDone={() => {

                                    this.setState({do_reload: false});
                                }}

                                tabbed_filters={{
                                    filters: [
                                        {key: 'tax_id', label: 'Tax Classes', options: this.state.tax_classes, default: this.state.active_tab, hide_all: true}
                                    ],
                                    type: 'tabs'
                                }}

                                onTabClick={(num) => {

                                    this.setState({active_tab: num})

                                    let post_fields = [];

                                    post_fields.push({key: 'tax_id', value: num})

                                    this.setState({post_fields: post_fields})

                                    const _tax_class = this.state.tax_classes.find(row => row.key === num);

                                    if(_tax_class){

                                        this.setState({active_tax_class_label: _tax_class.value})
                                    }
                                }}

                                columns={[
                                    {name: 'Country', column: 'country', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.countries},
                                    {name: 'State', column: 'state_code', sortable: true},
                                    {name: 'Label', column: 'label', sortable: true},
                                    {name: 'Tax', column: 'tax', sortable: true, renderer: (row) => {

                                        return <span className='fs-13 op-7'>{row.tax} %</span>
                                    }},
                                    {name: 'Priority', column: 'priority', sortable: true},
                                    {name: 'Apply On Shipping', column: 'apply_on_shipping', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'No'}, {key: '1', value: 'Yes'}]},
                                    {name: 'Status', column: 'status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Disabled'}, {key: '1', value: 'Enabled'}]},
                                ]}

                                row_actions={(row, row_index) => {

                                    return (

                                        <div className="hoverable-action">
                                            <div className="align-start">

                                                <Btn onClick={() => {

                                                    if(row.country !== ''){
                                                    
                                                        this.updateCountryState(row.country)
                                                    }

                                                    const _tax_class = this.state.tax_classes.find(row => row.key === row.tax_id);

                                                    if(_tax_class){

                                                        this.setState({active_tax_class_label: _tax_class.value})
                                                    }

                                                    this.setState({add_new: true, row_id: row.row_id})
                                                }} size="small" color="secondary" startIcon={<Edit style={{fontSize: 15}} />}>
                                                    Edit
                                                </Btn>
                                            </div>
                                        </div>
                                    )
                                }}

                                default_sort_by="added_on"

                                api_url="backend/sales/tax/slabs/listing"

                                account_token={this.state.account_token}
                                
                                row_id="row_id"
                            />
                        :
                            this.state.initing === false &&
                            
                                <NoData
                                    size="small"
                                    message="Tax Classes Not Created!"
                                />
                    }

                    <WdForm
                        title={`Tax Slab - ${this.state.active_tax_class_label}`}

                        drawer={true}
                        open={this.state.add_new}
                        position="bottom"
                        size="small"

                        submit_url='backend/sales/tax/slabs/save'
                        data_url='backend/sales/tax/slabs/data'

                        onSubmit={(result) => {
            
                            this.setState({add_new: false, do_reload: true, row_id: false})
                        }}
                        onBack={() => {
            
                            this.setState({add_new: false, row_id: false})
                        }}

                        row_id={this.state.row_id}
                        id="row_id"
                        title_field="label"
                        updated_on="updated_on_formatted"

                        post_fields={this.state.post_fields}

                        is_view={this.state.is_view}

                        fields={{
                            rows: [
                                {
                                    fields: [
                                        {key: 'country', name: 'country', label: 'Country', type: 'dropdown', options: this.state.countries, span: 12, validations: ['r'], onChange:(value) => {

                                            this.setState({selected_states: []})
                                            this.updateCountryState(value)
                                        }}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'state_code', name: 'state_code', label: 'State', type: 'dropdown', options: this.state.selected_states, span: 12, validations: ['r']}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'label', type: 'input', name: 'label', label: 'Label', validations: ['r'], span: 12}
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'tax', type: 'input', name: 'tax', label: 'Tax', validations: ['r', 'num'], span: 12, 'after': '%', comment: 'Enter number only.'},
                                    ],
                                },
                                {
                                    fields: [
                                        {key: 'priority', type: 'input', name: 'priority', label: 'Priority', validations: ['num'], span: 6},
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'apply_on_shipping', name: 'apply_on_shipping', label: 'Apply on shipping', type: 'switch', options: [{key: '0', value: 'No'}, {key: '1', value: 'Yes'}], span: 12}
                                    ]
                                },
                                {
                                    fields: [
                                        {key: 'status', name: 'status', label: 'Status', type: 'switch', options: [{key: '0', value: 'Disabled'}, {key: '1', value: 'Enabled'}], span: 12}
                                    ]
                                },
                            ]
                        }}
                    />
                </>
            </Main>
        )
    }

    updateCountryState = (selected_country) => {

        if(selected_country !== ''){

            this.setState({selected_country: selected_country}, () => {

                let country_states = this.state.country_states;

                let _states = country_states.filter(row => row.country === selected_country)

                this.setState({selected_states: _states})
            })
        }
    }

    init = (account_token) => {

        const formData = new FormData();
        formData.append('account_token', account_token);

        var self = this;

        this.setState({submitting: true});

        Api.post('backend/sales/tax/slabs/init', formData, function(data){

            self.setState({initing: false})

            if(data.status){

                self.setState({tax_classes: data.tax_classes, countries: data.countries, country_states: data.states})

                if(data.tax_classes.length > 0){

                    self.setState({active_tab: data.tax_classes[0].key})

                    let post_fields = [];

                    post_fields.push({key: 'tax_id', value: data.tax_classes[0].key})

                    self.setState({post_fields: post_fields, active_tax_class_label: data.tax_classes[0].value})
                }
            }else{

                this.setState({submitting: false});

                LayoutHelper.addErrorMessage(self, data.message);
            }
        });
    }
}

export default SalesTaxSlabs;