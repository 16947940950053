import React, { Component } from 'react';

import Grid from '@mui/material/Grid';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Loader from 'components/Loader';

class ShippingMethods extends Component {

    constructor(props) {
        super();
        this.state = {

            loading: false
        }
    }

    render(){

        return (
            <div style={{position: 'relative'}}>

                <FormControl>
                    <RadioGroup
                        onChange={(e) => {

                            this.props.onSelect(e.target.value)
                        }}
                        value={this.props.selected_shipping_method}
                    >
                        {this.props.methods.map((_shipping_method) => {

                            return (
                                
                                <FormControlLabel key={`shipping_method_${_shipping_method.code}`} value={_shipping_method.code} control={<Radio />} label={this.radioLabel(_shipping_method)} />
                            )
                        })}
                    </RadioGroup>
                </FormControl>

                <Loader loading={this.props.loading || this.state.loading} />
            </div>
        )
    }

    radioLabel = (item) => {

        return (
            <div className='vertical'>
                <strong className='fs-12 op-8'>{item.label} {item.price !== '' ? <span className='op-8'>(+{item.price})</span> : ''}</strong>
                <span className='fs-12 op-5'>{item.details}</span>
            </div>
        )
    }
}

export default ShippingMethods;