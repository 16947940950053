'use strict';

const Constants = {
    URL: {
        root: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_ROOT_DEV : process.env.REACT_APP_ROOT_PROD,
        api_url: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD,
        server_url: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SERVER_URL_DEV : process.env.REACT_APP_SERVER_URL_PROD,
    },
    API_KEY: process.env.REACT_APP_API_KEY
}

module.exports = Constants;
